*, :before, :after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  border-color: rgb(var(--border-color));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  scroll-padding-top: var(--sticky-area-height);
  line-height: 1.5;
}

body {
  --background: var(--background-primary);
  --text-color: var(--text-primary);
  background: rgb(var(--background));
  color: rgb(var(--text-color));
  font: var(--text-font-style) var(--text-font-weight) var(--text-base) / 1.65 var(--text-font-family);
  letter-spacing: var(--text-letter-spacing);
  margin: 0;
  position: relative;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  text-transform: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

input[type="submit"] {
  cursor: pointer;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

button, label, summary, [role="button"], [type="checkbox"], [type="radio"] {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  list-style-type: none;
}

summary::-webkit-details-marker {
  display: none;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ul, ol, menu {
  margin: 0;
  padding: 0;
}

.unstyled-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: rgb(var(--text-color) / .65);
}

:disabled {
  cursor: default !important;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img {
  color: #0000;
  max-width: 100%;
  height: auto;
}

[role="img"] svg {
  display: initial;
}

picture source {
  display: contents;
}

video {
  max-width: 100%;
  height: auto;
}

:focus:not(:focus-visible) {
  outline: none;
}

[hidden] {
  display: none !important;
}

height-observer {
  display: block;
}

.accordion {
  box-sizing: content-box;
}

.accordion {
  border-block-width: 1px;
}

.accordion :is(svg) {
  transition: transform .2s;
}

.accordion + .accordion {
  border-block-start-width: 0;
}

.accordion__toggle {
  justify-content: space-between;
  align-items: center;
  column-gap: .625rem;
  padding-block: 1.25rem;
  display: flex;
}

.accordion__content {
  margin-block-start: -.25rem;
  padding-block-end: 1.25rem;
  padding-inline-end: 1.25rem;
}

@media screen and (min-width: 1000px) {
  .accordion__toggle.text-lg {
    padding-block: 1.5rem;
  }

  .accordion--lg .accordion__toggle {
    padding-block: 2rem;
  }

  .accordion--lg .accordion__content {
    margin-block-start: -.5rem;
  }
}

.accordion-group > * + * > .accordion {
  border-block-start-width: 0;
}

.blog-post-card {
  align-content: start;
  gap: 1.25rem;
  display: grid;
}

.blog-post-card__info {
  justify-items: start;
  row-gap: 1rem;
  display: grid;
}

.blog-post-list {
  --blog-post-list-items-per-row: 1;
  --blog-post-list-column-gap: 1.875rem;
  --blog-post-list-row-gap: var(--section-stack-gap);
  --blog-post-list-card-width: calc(100% / var(--blog-post-list-items-per-row)  - var(--blog-post-list-column-gap) * (var(--blog-post-list-items-per-row)  - 1) / var(--blog-post-list-items-per-row));
  grid: auto / repeat(auto-fit, var(--blog-post-list-card-width));
  align-items: start;
  column-gap: var(--blog-post-list-column-gap);
  row-gap: var(--blog-post-list-row-gap);
  display: grid;
}

.featured-blog-post {
  grid-column: 1 / -1;
  display: none;
}

@media screen and (min-width: 700px) {
  .blog-post-list {
    --blog-post-list-items-per-row: 2;
  }
}

@media screen and (max-width: 999px) {
  .blog-post-list--carousel {
    --blog-post-list-card-width: 40vw;
    grid: auto / auto-flow var(--blog-post-list-card-width);
  }
}

@media screen and (max-width: 699px) {
  .blog-post-list--carousel {
    --blog-post-list-card-width: 74vw;
  }
}

@media screen and (min-width: 1000px) {
  .blog-post-list {
    --blog-post-list-items-per-row: 3;
    --blog-post-list-column-gap: 3.75rem;
  }

  .featured-blog-post {
    display: block;
  }

  .featured-blog-post + .blog-post-card {
    display: none;
  }
}

@media screen and (min-width: 1150px) {
  .blog-post-list {
    --blog-post-list-row-gap: 3.75rem;
  }
}

.shopify-challenge__button, .shopify-payment-button__button--unbranded {
  all: revert;
}

.button, .shopify-challenge__button, .shopify-payment-button__button--unbranded {
  --initial-gradient: linear-gradient(rgb(var(--button-background, var(--button-background-primary))), rgb(var(--button-background, var(--button-background-primary))));
  --hover-gradient: linear-gradient(transparent, transparent);
  --initial-background-position: var(--transform-origin-end);
  --hover-background-position: var(--transform-origin-start);
  -webkit-appearance: none;
  appearance: none;
  text-transform: var(--button-text-transform);
  font: var(--button-font);
  letter-spacing: var(--button-letter-spacing);
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  color: rgb(var(--button-text-color, var(--button-text-primary)));
  border: 1px solid rgb(var(--button-outline-color, var(--button-background, var(--button-background-primary))));
  border-radius: var(--button-border-radius);
  background-color: #0000;
  background-image: var(--initial-gradient), var(--hover-gradient);
  background-size: 100% 100%, 0 100%;
  background-position: var(--initial-background-position);
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  column-gap: 1.125rem;
  padding: .65rem 1.75rem;
  transition: background-size .45s cubic-bezier(.785, .135, .15, .86), background-position .45s step-end, color .45s cubic-bezier(.785, .135, .15, .86), border .45s cubic-bezier(.785, .135, .15, .86);
  display: inline-flex;
  position: relative;
}

.button--outline {
  --initial-gradient: linear-gradient(transparent, transparent);
  --hover-gradient: linear-gradient(rgb(var(--button-background, var(--button-background-primary))), rgb(var(--button-background, var(--button-background-primary))));
  --initial-background-position: var(--transform-origin-start);
  --hover-background-position: var(--transform-origin-end);
  border-color: rgb(var(--button-outline-color, var(--border-color)));
  color: rgb(var(--button-background, var(--text-color)));
}

.button--subdued.button--outline {
  border-color: rgb(var(--border-color));
  color: rgb(var(--button-background, var(--button-background-primary)) / .65);
}

.button--sm {
  font-size: var(--text-xs);
  padding: .5rem .75rem;
}

@media screen and (pointer: fine) and (prefers-reduced-motion: no-preference) {
  .features--button-transition :is(.button, .shopify-challenge__button, .shopify-payment-button__button--unbranded):not([disabled]):hover {
    border-color: rgb(var(--button-background, var(--button-background-primary)));
    color: rgb(var(--button-background, var(--button-background-primary)));
    background-position: var(--hover-background-position);
    background-size: 0 100%, 100% 100%;
  }

  .features--button-transition .shopify-payment-button__button--unbranded:not([disabled]):hover {
    background-color: #0000;
  }

  .features--button-transition .button--outline:not([disabled]):hover {
    color: rgb(var(--button-text-color, var(--button-text-primary)));
  }
}

.button-group {
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.button-group > * {
  margin: 0 !important;
}

.button-group:has(.link):has(.button) {
  column-gap: 1.5rem;
}

@media screen and (min-width: 700px) {
  .button-group {
    gap: 1.25rem;
  }

  .button-group:has(.link):not(:has(.button)) {
    column-gap: 2rem;
  }

  .button-group--same-width {
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    display: grid;
  }
}

.shopify-payment-button__button {
  width: 100%;
  min-height: calc(1lh + 1.3rem + 2px);
  vertical-align: top;
}

.shopify-payment-button__button--branded:focus-within {
  outline: 2px solid Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.shopify-payment-button__button--branded [role="button"] {
  border-radius: var(--button-border-radius);
  padding: .875rem 1.75rem;
}

.shopify-payment-button__more-options[aria-hidden="true"] {
  display: none;
}

@keyframes animateIconInline {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  50% {
    opacity: 0;
    transform: translateX(100%);
  }

  51% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes animateIconBlock {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  50% {
    opacity: 0;
    transform: translateY(100%);
  }

  51% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.circle-button {
  --circle-button-size: 2.75rem;
  --circle-button-background: var(--button-text-primary);
  --circle-button-text-color: var(--button-background-primary);
  width: var(--circle-button-size);
  height: var(--circle-button-size);
  background: rgb(var(--circle-button-background));
  color: rgb(var(--circle-button-text-color));
  border-radius: var(--rounded-full);
  box-shadow: 0 .125rem .625rem rgb(var(--circle-button-text-color) / .15);
  place-items: center;
  display: grid;
}

.circle-button > svg {
  width: .75rem;
}

@media screen and (pointer: fine) and (prefers-reduced-motion: no-preference) {
  .circle-button.hover\:animate-icon-inline:hover svg {
    animation: .35s ease-in-out forwards animateIconInline;
  }

  .circle-button.hover\:animate-icon-block:hover svg {
    animation: .35s ease-in-out forwards animateIconBlock;
  }
}

.circle-button--sm {
  --circle-button-size: 2.25rem;
}

.circle-button--lg, .circle-button--xl {
  --circle-button-size: 3.125rem;
}

:is(.circle-button--lg, .circle-button--xl) > svg {
  width: 1rem;
}

@media screen and (min-width: 700px) {
  .circle-button--xl {
    --circle-button-size: 3.5rem;
  }
}

.content-over-media {
  --content-over-media-gap: 1.25rem;
  --content-over-media-calculated-row-gap: var(--content-over-media-row-gap, var(--content-over-media-gap));
  --content-over-media-calculated-column-gap: var(--content-over-media-column-gap, var(--content-over-media-gap));
  grid-template: [full-start] 0 [content-start] minmax(0, 1fr) [content-end] 0 [full-end] / [full-start] minmax(0, 1fr) [content-start] minmax(0, min(var(--container-max-width), 100% - var(--content-over-media-column-gap, var(--content-over-media-gap, var(--container-gutter))) * 2)) [content-end] minmax(0, 1fr) [full-end];
  row-gap: var(--content-over-media-calculated-row-gap);
  column-gap: var(--content-over-media-calculated-column-gap);
  min-height: var(--content-over-media-height, auto);
  place-items: center;
  display: grid;
  position: relative;
  overflow: hidden;
}

.content-over-media:before {
  content: "";
  background: var(--content-over-media-gradient-overlay, rgb(var(--content-over-media-overlay)));
  border-radius: inherit;
  z-index: 1;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.content-over-media > picture {
  display: contents;
}

.content-over-media > :is(img, video, iframe, svg, video-media), .content-over-media > picture img {
  height: var(--content-over-media-height, auto);
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  overflow-wrap: anywhere;
  object-fit: cover;
  object-position: center;
  border-radius: inherit;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  grid-area: full-start / full-start / full-end / full-end;
}

.content-over-media > :not(img, video, iframe, svg, video-media, picture) {
  max-width: var(--content-over-media-content-max-width, 48rem);
  z-index: 1;
  text-shadow: 0 1px rgb(var(--content-over-media-text-shadow) / 50%);
  grid-area: content-start / content-start / content-end / content-end;
  position: relative;
}

.content-over-media:has( > .prose):not(:has( > .prose .button:last-child)) {
  row-gap: calc(var(--content-over-media-calculated-row-gap)  - .5rem);
}

@supports (row-gap: 1lh) {
  .content-over-media:has( > .prose):not(:has( > .prose .button:last-child)) {
    row-gap: calc(var(--content-over-media-calculated-row-gap)  - .5lh);
  }
}

.content-over-media > video-media:not([autoplay]) ~ * {
  pointer-events: none;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}

.content-over-media > video-media:not([autoplay])[loaded] ~ * {
  opacity: 0;
  visibility: hidden;
}

.content-over-media:has(video-media:not([autoplay])) .play-button {
  transition: transform .2s ease-in-out;
}

.content-over-media:has(video-media:not([autoplay])):hover .play-button {
  transform: scale(1.1);
}

.shopify-section:first-child [allow-transparent-header] > .content-over-media > :not(img, video, iframe, svg, video-media, picture) {
  padding-block-start: var(--header-height, 0px);
}

.content-over-media--xs {
  --content-over-media-height: clamp(15rem, 65vw, 25rem);
}

.content-over-media--sm {
  --content-over-media-height: clamp(20rem, 85vw, 32rem);
}

.content-over-media--md {
  --content-over-media-height: clamp(25rem, 100vw, 36rem);
}

.content-over-media--lg {
  --content-over-media-height: clamp(30rem, 125vw, 42rem);
}

.content-over-media--fill {
  --content-over-media-height: calc(100vh - var(--sticky-area-height));
}

.shopify-section:first-child .content-over-media--fill {
  --content-over-media-height: calc(100vh - (var(--announcement-bar-height, 0px)  + var(--header-height, 0px)));
}

.shopify-section:first-child:has([allow-transparent-header]) .content-over-media--fill {
  --content-over-media-height: calc(100vh - (var(--announcement-bar-height, 0px)));
}

@supports (height: 100svh) {
  .content-over-media--fill {
    --content-over-media-height: calc(100svh - var(--sticky-area-height));
  }

  .shopify-section:first-child .content-over-media--fill {
    --content-over-media-height: calc(100svh - (var(--announcement-bar-height, 0px)  + var(--header-height, 0px)));
  }

  .shopify-section:first-child:has([allow-transparent-header]) .content-over-media--fill {
    --content-over-media-height: calc(100svh - (var(--announcement-bar-height, 0px)));
  }
}

@media screen and (min-width: 700px) {
  .content-over-media {
    --content-over-media-gap: 2rem;
  }
}

@media screen and (min-width: 1000px) {
  .content-over-media {
    --content-over-media-gap: 3rem;
  }
}

.page-dots {
  --dot-size: .375rem;
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem 1rem;
  display: flex;
}

.page-dots > * {
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--rounded-full);
  place-content: center;
  display: grid;
}

.page-dots > *:after {
  content: "";
  border-radius: inherit;
  opacity: .4;
  background: currentColor;
  transition: opacity .25s, background .25s;
  position: absolute;
  inset: 0;
}

.page-dots > *[aria-current="true"]:after {
  opacity: 1;
}

.page-dots--narrow {
  column-gap: .5rem;
}

.page-dots--autoplay > [aria-current="false"]:after, .page-dots--autoplay > [aria-current="true"] .circular-progress {
  transition-delay: .15s;
}

.page-dots--autoplay > [aria-current="true"]:after, .page-dots--autoplay > [aria-current="false"] .circular-progress {
  opacity: 0;
}

.page-dots--autoplay > [aria-current="true"] .circular-progress circle:last-child {
  animation: animateCircularProgress var(--slideshow-progress-duration) linear var(--slideshow-progress-play-state) both;
}

.prev-next-button {
  transition: opacity .15s ease-in-out, transform .15s ease-in-out, visibility .15s ease-in-out;
}

.prev-next-button[disabled] {
  opacity: 0;
  visibility: hidden;
}

@media screen and (pointer: fine) {
  .prev-next-button[is="carousel-prev-button"]:hover svg {
    animation-direction: reverse;
  }
}

@media screen and (pointer: fine) {
  .floating-controls-container {
    padding-inline: 4.625rem;
    display: block;
    position: relative;
  }

  .floating-controls-container > .prev-next-button {
    display: none;
  }

  .floating-controls-container > .is-scrollable ~ .prev-next-button {
    z-index: 1;
    display: grid;
    position: absolute;
    inset-block-start: calc(50% - 1.5625rem);
  }

  .floating-controls-container > .prev-next-button--prev {
    inset-inline-start: var(--floating-controls-inset-inline, 0);
  }

  .floating-controls-container > .prev-next-button--next {
    inset-inline-end: var(--floating-controls-inset-inline, 0);
  }

  .floating-controls-container--inside {
    --floating-controls-inset-inline: 1.5rem;
    padding-inline: 0;
  }

  .floating-controls-container--on-hover > .prev-next-button {
    opacity: 0;
    transform: translateY(-10px);
  }

  .floating-controls-container--on-hover:hover > .prev-next-button:not([disabled]) {
    opacity: 1;
    transform: translateY(0);
  }
}

@media not screen and (pointer: fine) {
  .floating-controls-container > .prev-next-button {
    display: none;
  }
}

:where(x-drawer, x-modal, x-popover):not(:defined) {
  display: none;
}

:where(.modal, .drawer, .popover, .header-search) {
  z-index: 999;
  display: none;
  position: relative;
}

:where(.modal, .drawer, .popover, .header-search)::part(overlay) {
  min-height: 100vh;
  background: rgb(var(--page-overlay));
  position: fixed;
  inset: 0;
}

:where(.modal, .drawer, .popover, .header-search)::part(close-button) {
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  place-items: center;
  padding: 0;
  display: grid;
}

:where(.modal, .drawer, .popover, .header-search)::part(close-button) > svg {
  display: block;
}

@media screen and (pointer: fine) {
  :where(.modal, .drawer, .popover, .header-search)::part(close-button) {
    opacity: .6;
    transition: all .2s ease-in;
  }

  :where(.modal, .drawer, .popover, .header-search)::part(close-button):hover {
    opacity: 1;
  }
}

:where(.modal, .drawer, .popover, .header-search)::part(tap-area) {
  position: relative;
}

:where(.modal, .drawer, .popover, .header-search)::part(tap-area):before {
  content: "";
  position: absolute;
  inset: -.65rem;
}

.modal {
  --background: var(--modal-background);
  --text-color: var(--modal-text);
  --border-color: var(--modal-border-color);
  --distance-to-bleed: var(--modal-body-padding-inline);
  --modal-header-padding-block: .875rem;
  --modal-header-padding-inline: 1.25rem;
  --modal-body-padding-block: 1.25rem;
  --modal-body-padding-inline: 1.25rem;
  --modal-body-max-width: 30rem;
}

.modal::part(base) {
  height: 100%;
  width: 100%;
  place-items: end center;
  display: grid;
  position: fixed;
  inset: 0;
}

.modal::part(content) {
  width: 100%;
  max-width: var(--modal-body-max-width);
  max-height: 75vh;
  background: rgb(var(--background));
  color: rgb(var(--text-color));
  isolation: isolate;
  overflow: auto;
}

@supports (height: 100dvh) {
  .modal::part(content) {
    max-height: 85dvh;
  }
}

.modal::part(header) {
  background: inherit;
  border-block-end: 1px solid rgb(var(--border-color));
  text-align: center;
  z-index: 2;
  align-items: center;
  padding-block-start: var(--modal-header-padding-block);
  padding-block-end: var(--modal-header-padding-block);
  padding-inline-start: var(--modal-header-padding-inline);
  padding-inline-end: var(--modal-header-padding-inline);
  display: grid;
  position: sticky;
  inset-block-start: 0;
}

.modal::part(close-button) {
  position: absolute;
  inset-inline-end: var(--modal-header-padding-inline);
}

.modal::part(body) {
  padding-block-start: var(--modal-body-padding-block);
  padding-block-end: var(--modal-body-padding-block);
  padding-inline-start: var(--modal-body-padding-inline);
  padding-inline-end: var(--modal-body-padding-inline);
}

@media screen and (min-width: 700px) {
  .modal {
    --modal-header-padding-block: 1.125rem;
    --modal-header-padding-inline: 2rem;
    --modal-body-padding-block: 2rem;
    --modal-body-padding-inline: 2rem;
  }

  .modal::part(base) {
    place-items: center;
  }

  .modal--lg {
    --modal-body-max-width: 45rem;
  }
}

.drawer {
  --background: var(--modal-background);
  --text-color: var(--modal-text);
  --border-color: var(--modal-border-color);
  --distance-to-bleed: var(--drawer-body-padding-inline);
  --drawer-header-padding-block: 1rem;
  --drawer-header-padding-inline: 1.25rem;
  --drawer-body-padding-block: 1.25rem;
  --drawer-body-padding-inline: 1.25rem;
  --drawer-footer-padding-block: 1rem;
  --drawer-footer-padding-inline: 1.25rem;
  --drawer-max-width: min(92vw, 28.125rem);
}

.drawer::part(base) {
  height: 100%;
  width: 100%;
  position: fixed;
  inset: 0;
}

.drawer::part(content) {
  height: 100%;
  width: 100%;
  max-width: var(--drawer-max-width);
  background: rgb(var(--background));
  color: rgb(var(--text-color));
  grid-auto-rows: auto minmax(0, 1fr) auto;
  align-content: start;
  display: grid;
  position: relative;
}

.drawer::part(header) {
  min-height: min(var(--header-height), 5rem);
  border-block-end: 1px solid rgb(var(--border-color));
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding-block-start: var(--drawer-header-padding-block);
  padding-block-end: var(--drawer-header-padding-block);
  padding-inline-start: var(--drawer-header-padding-inline);
  padding-inline-end: var(--drawer-header-padding-inline);
  display: flex;
}

.drawer::part(body) {
  overscroll-behavior-y: contain;
  grid-template-columns: minmax(0, 1fr);
  align-content: start;
  padding-block-start: var(--drawer-body-padding-block);
  padding-block-end: var(--drawer-body-padding-block);
  padding-inline-start: var(--drawer-body-padding-inline);
  padding-inline-end: var(--drawer-body-padding-inline);
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
}

.drawer::part(footer) {
  background: rgb(var(--background));
  border-block-start: 1px solid rgb(var(--border-color));
  padding-block-start: var(--drawer-footer-padding-block);
  padding-block-end: var(--drawer-footer-padding-block);
  padding-inline-start: var(--drawer-footer-padding-inline);
  padding-inline-end: var(--drawer-footer-padding-inline);
}

.drawer--sm {
  --drawer-max-width: min(92vw, 25rem);
}

.drawer--center-body::part(body) {
  align-self: center;
}

@media screen and (min-width: 1000px) {
  .drawer {
    --drawer-header-padding-block: 1.125rem;
    --drawer-header-padding-inline: 2rem;
    --drawer-body-padding-block: 2rem;
    --drawer-body-padding-inline: 2rem;
    --drawer-footer-padding-block: 2rem;
    --drawer-footer-padding-inline: 2rem;
  }
}

.popover {
  --background: var(--popover-background);
  --text-color: var(--popover-text);
  --border-color: var(--popover-border-color);
  --distance-to-bleed: var(--popover-body-padding-inline);
  --popover-header-padding-block: .875rem;
  --popover-header-padding-inline: 1.25rem;
  --popover-body-padding-block: 1.25rem;
  --popover-body-padding-inline: 1.25rem;
  --distance-to-bleed: var(--popover-body-padding-inline);
}

.popover::part(content) {
  width: 100%;
  max-height: 75vh;
  background: rgb(var(--background));
  color: rgb(var(--text-color));
  isolation: isolate;
  overflow: auto;
}

@supports (height: 100dvh) {
  .popover::part(content) {
    max-height: 85dvh;
  }
}

.popover::part(header) {
  background: inherit;
  border-block-end: 1px solid rgb(var(--border-color));
  text-align: center;
  z-index: 2;
  align-items: center;
  padding-block-start: var(--popover-header-padding-block);
  padding-block-end: var(--popover-header-padding-block);
  padding-inline-start: var(--popover-header-padding-inline);
  padding-inline-end: var(--popover-header-padding-inline);
  display: grid;
  position: sticky;
  inset-block-start: 0;
}

.popover::part(close-button) {
  position: absolute;
  inset-inline-end: var(--popover-header-padding-inline);
}

.popover::part(body) {
  padding-block-start: var(--popover-body-padding-block);
  padding-block-end: var(--popover-body-padding-block);
  padding-inline-start: var(--popover-body-padding-inline);
  padding-inline-end: var(--popover-body-padding-inline);
}

.popover__value-list {
  text-align: center;
  justify-items: center;
  gap: .75rem;
  display: grid;
}

.popover__value-list > * {
  text-align: inherit;
  width: 100%;
  color: rgb(var(--text-color) / .65);
  transition: color .2s ease-in-out;
}

.popover__value-list > *[aria-selected="true"], .popover__value-list > *:hover {
  color: rgb(var(--text-color));
}

@media screen and (max-width: 999px) {
  .popover::part(base) {
    height: 100%;
    width: 100%;
    place-items: end center;
    display: grid;
    position: fixed;
    inset: 0;
  }
}

@media screen and (min-width: 1000px) {
  .popover {
    --popover-block-offset: .75rem;
    position: absolute;
  }

  .popover::part(content) {
    width: max-content;
    max-width: 18.75rem;
    max-height: min(60vh, 25rem);
    border: 1px solid rgb(var(--border-color));
    box-shadow: 2px 2px 6px rgb(var(--text-color) / .05);
  }

  .popover::part(overlay), .popover::part(header) {
    display: none;
  }

  .popover__value-list {
    text-align: start;
    justify-items: start;
  }

  .popover:has(.popover__value-list)::part(body) {
    padding-inline-end: 2.5rem;
  }

  .popover--top-start {
    inset-block-end: calc(100% + var(--popover-block-offset));
    inset-inline-start: 0;
  }

  .popover--top-end {
    inset-block-end: calc(100% + var(--popover-block-offset));
    inset-inline-end: 0;
  }

  .popover--bottom-start {
    inset-block-start: calc(100% + var(--popover-block-offset));
    inset-inline-start: 0;
  }

  .popover--bottom-end {
    inset-block-start: calc(100% + var(--popover-block-offset));
    inset-inline-end: 0;
  }
}

.pop-in {
  z-index: 10;
  padding: 2rem;
  display: none;
  position: fixed;
  inset-block-end: 1rem;
  inset-inline: 1rem;
}

.pop-in__close-button {
  inset-block-start: 1.25rem;
  inset-inline-end: 1.25rem;
  position: absolute !important;
}

@media screen and (min-width: 700px) {
  .pop-in {
    inset-block-end: 2rem;
    inset-inline: 2rem;
  }
}

.banner {
  font-size: var(--text-base);
  color: rgb(var(--banner-color));
  background: rgb(var(--banner-background));
  text-shadow: none;
  padding: .625rem 1.25rem;
}

.banner:has(.banner__text-with-button) {
  padding-block: 1.25rem;
}

.banner--error {
  --banner-background: var(--error-background);
  --banner-color: var(--error-text);
  --button-background: var(--error-text);
  --button-text-color: var(--error-background);
}

.banner--success {
  --banner-background: var(--success-background);
  --banner-color: var(--success-text);
  --button-background: var(--success-text);
  --button-text-color: var(--success-background);
}

.banner--warning {
  --banner-background: var(--warning-background);
  --banner-color: var(--warning-text);
  --button-background: var(--warning-text);
  --button-text-color: var(--warning-background);
}

.banner__text-with-button {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: .8rem 1.5rem;
  display: flex;
}

.form {
  align-content: start;
  row-gap: var(--form-gap);
  display: grid;
}

.form-row {
  gap: var(--fieldset-gap);
  flex-wrap: wrap;
  display: flex;
}

.form-row > .form-control {
  flex: 1 0 var(--fieldset-row-control-width, 14rem);
}

.form-row > button {
  flex-grow: 1;
}

.fieldset {
  gap: var(--fieldset-gap);
  display: grid;
}

.fieldset-row {
  gap: inherit;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  display: grid;
}

.form-control {
  gap: var(--form-control-gap);
  display: grid;
  position: relative;
}

.form-control > label {
  justify-self: start;
}

.form-control__max-characters-count {
  margin-block-start: -.375rem;
}

.checkbox-control {
  align-items: flex-start;
  column-gap: var(--checkbox-control-gap);
  text-align: start;
  display: flex;
  position: relative;
}

.input, .textarea, .select {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  padding: var(--input-padding-block) var(--input-padding-inline);
  border-radius: var(--input-border-radius);
  background: rgb(var(--input-background, transparent));
  color: rgb(var(--input-text-color, var(--text-color)));
  text-align: start;
  border-width: 1px;
  transition: border-color .1s ease-in-out;
}

:is(.input, .textarea, .select)::placeholder {
  color: rgb(var(--input-text-color, var(--text-color)) / .6);
}

.input:focus, .textarea:focus, .select:focus-visible {
  border-color: currentColor;
  outline: none;
}

.select {
  box-shadow: 0 1px 3px rgb(var(--text-color) / .08);
}

.select:required:invalid {
  color: rgb(var(--input-text-color, var(--text-color)) / .6);
}

.select ~ svg, .select > svg {
  transition: transform .2s;
  position: absolute;
  inset-block-start: calc(50% - 3.5px);
  inset-inline-end: calc(var(--input-padding-inline)  + 1px);
}

.select[aria-expanded="true"] > svg {
  transform: rotateZ(180deg);
}

.form-control:has(.select):before {
  content: "";
  width: calc(var(--input-padding-inline) * 2 + 35px);
  height: calc(100% - 2px);
  background-image: linear-gradient(to var(--transform-origin-start), rgb(var(--background)) calc(var(--input-padding-inline)  + 20px), rgb(var(--background) / 0));
  pointer-events: none;
  position: absolute;
  inset-block-start: 1px;
  inset-inline-end: 1px;
}

.form-control:has(.select):not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-right-radius: var(--input-border-radius);
  border-bottom-right-radius: var(--input-border-radius);
}

.form-control:has(.select):is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):before {
  border-top-left-radius: var(--input-border-radius);
  border-bottom-left-radius: var(--input-border-radius);
}

.floating-label {
  z-index: 1;
  background: rgb(var(--background));
  color: rgb(var(--input-text-color, var(--text-color)) / .6);
  opacity: 0;
  pointer-events: none;
  padding: 0 .25rem;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  position: absolute;
  inset-block-start: calc(-1 * var(--input-padding-block)  + 1px);
  inset-inline-start: calc(var(--input-padding-inline)  - .25rem + 1px);
  transform: translateY(3px);
}

:-webkit-autofill ~ .floating-label {
  color: #000;
}

:is(.input, .textarea):not(:placeholder-shown) ~ .floating-label, :is(.input, .textarea, .select):-webkit-autofill ~ .floating-label, .select:valid ~ .floating-label {
  opacity: 1;
  transform: translateY(0);
}

.input-suffix {
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
  position: absolute;
  inset-block-start: var(--input-padding-block);
  inset-inline-end: var(--input-padding-inline);
}

.form-control:has(:focus-within) + .input-suffix.hide-on-focus {
  opacity: 0;
  visibility: hidden;
}

.checkbox {
  --checkbox-baseline-distance: calc(1em * 1.6);
  --checkbox-offset: calc((var(--checkbox-baseline-distance)  - var(--checkbox-size)) / 2);
  -webkit-appearance: none;
  appearance: none;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border-width: 1px;
  border-color: rgb(var(--text-color) / .8);
  background: var(--checkmark-svg-url) no-repeat center / 0;
  border-radius: 2px;
  flex-shrink: 0;
  transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
  position: relative;
  inset-block-start: var(--checkbox-offset);
}

@supports (width: 1lh) {
  .checkbox {
    inset-block-start: calc(.5lh - var(--checkbox-size) / 2);
  }
}

.checkbox:checked {
  background-color: rgb(var(--accent));
  border-color: rgb(var(--accent));
  background-size: 10px 8px;
}

.checkbox:checked ~ label {
  opacity: 1;
}

.checkbox:disabled, .checkbox:disabled ~ label {
  opacity: .5;
  cursor: default;
}

.checkbox:not(:disabled, :checked) ~ label {
  opacity: .7;
}

.checkbox ~ label {
  transition: all .2s ease-in-out;
}

.dot-checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 6px;
  background: rgb(var(--text-color));
  opacity: 0;
  pointer-events: none;
  border-radius: 100%;
  transition: opacity .2s;
  display: block;
  position: absolute;
  inset-block-start: calc(.5em + 1.5px);
}

.dot-checkbox ~ label {
  opacity: .65;
  transition: opacity .2s, transform .2s;
  position: relative;
}

.dot-checkbox ~ label:hover {
  opacity: 1;
}

.dot-checkbox:checked {
  opacity: 1;
}

.dot-checkbox:checked ~ label {
  opacity: 1;
  transform: translateX(1.125rem);
}

.switch {
  --switch-height: 1rem;
  --switch-width: 2.25rem;
  --switch-padding: .1875rem;
  --switch-toggle-size: .625rem;
  -webkit-appearance: none;
  appearance: none;
  height: var(--switch-height);
  width: var(--switch-width);
  padding: var(--switch-padding);
  border-radius: var(--rounded-full);
  background: rgb(var(--text-color) / .1);
  align-self: center;
  transition: background .2s ease-in-out;
  display: grid;
}

.switch:after {
  content: "";
  width: var(--switch-toggle-size);
  height: var(--switch-toggle-size);
  background: rgb(var(--background-secondary));
  border-radius: var(--rounded-full);
  transition: transform .2s ease-in-out;
  box-shadow: 0 1px 1px #0003;
}

.switch:checked {
  background: rgb(var(--accent));
}

.switch:checked:after {
  transform: translateX(calc(var(--transform-logical-flip) * (var(--switch-width)  - var(--switch-toggle-size)  - var(--switch-padding) * 2)));
}

.shopify-section {
  --section-vertical-spacing: var(--section-with-border-vertical-spacing);
  --section-vertical-spacing-tight: var(--section-with-border-vertical-spacing-tight);
}

.shopify-section:has(.bordered-section) {
  --section-vertical-spacing: var(--section-without-border-vertical-spacing);
  --section-vertical-spacing-tight: var(--section-without-border-vertical-spacing-tight);
}

.shopify-section:not(:first-child):has(.bordered-section) {
  border-block-start-width: 1px;
}

.shopify-section:not(:has(.section-spacing)) + .shopify-section {
  border-block-start-width: 0;
}

@supports not selector(:has(p)) {
  .shopify-section--has-fallback-bordered {
    --section-vertical-spacing: var(--section-without-border-vertical-spacing);
    --section-vertical-spacing-tight: var(--section-without-border-vertical-spacing-tight);
  }

  .shopify-section--has-fallback-bordered:not(:first-child) {
    border-block-start-width: 1px;
  }
}

.container {
  --distance-to-bleed: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
  margin-inline-start: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
  margin-inline-end: max(var(--container-gutter), 50% - var(--container-max-width) / 2);
}

.container--xxs {
  --container-max-width: var(--container-xxs-max-width);
}

.container--xs {
  --container-max-width: var(--container-xs-max-width);
}

.container--sm {
  --container-max-width: var(--container-sm-max-width);
}

.container--md {
  --container-max-width: var(--container-md-max-width);
}

.container--lg {
  --container-max-width: var(--container-lg-max-width);
}

.container--xl {
  --container-max-width: var(--container-xl-max-width);
}

.section-spacing:not(.section-spacing--padded) {
  margin-block-start: calc(var(--section-vertical-spacing-override, var(--section-vertical-spacing))  + var(--section-vertical-spacing-block-start-compensation, 0px));
  margin-block-end: var(--section-vertical-spacing-override, var(--section-vertical-spacing));
}

.shopify-section:has(.section-spacing:not(.section-spacing--padded)) {
  scroll-margin-block-start: var(--sticky-area-height);
}

.section-spacing--tight {
  --section-vertical-spacing: var(--section-vertical-spacing-tight);
  --section-stack-gap: var(--section-stack-gap-tight);
}

.section-spacing--padded {
  padding-block-start: calc(var(--section-vertical-spacing-override, var(--section-vertical-spacing))  + var(--section-vertical-spacing-block-start-compensation, 0px));
  padding-block-end: var(--section-vertical-spacing-override, var(--section-vertical-spacing));
}

.shopify-section:empty {
  display: none;
}

.section-stack {
  gap: var(--section-stack-gap);
  grid-auto-columns: minmax(0, 1fr);
  display: grid;
}

.section-header {
  max-width: min(var(--container-xs-max-width), 100%);
  margin-inline-start: var(--container-gutter);
  margin-inline-end: var(--container-gutter);
}

.container .section-header {
  margin-inline: 0;
}

.v-stack {
  grid-template-columns: minmax(0, 1fr);
  align-content: start;
  display: grid;
}

.h-stack {
  align-items: center;
  display: flex;
}

.bleed, .full-bleed {
  margin-inline-start: calc(-1 * var(--distance-to-bleed));
  margin-inline-end: calc(-1 * var(--distance-to-bleed));
}

.bleed {
  scroll-padding-inline: var(--distance-to-bleed);
  padding-inline-start: var(--distance-to-bleed);
  padding-inline-end: var(--distance-to-bleed);
}

@media screen and (min-width: 700px) {
  .sm\:unbleed {
    margin-inline: 0;
    padding-inline: 0;
    scroll-padding-inline: 0;
  }
}

@media screen and (min-width: 1000px) {
  .md\:unbleed {
    margin-inline: 0;
    padding-inline: 0;
    scroll-padding-inline: 0;
  }
}

@media screen and (min-width: 1150px) {
  .lg\:unbleed {
    margin-inline: 0;
    padding-inline: 0;
    scroll-padding-inline: 0;
  }
}

.empty-state {
  text-align: center;
  place-content: center;
  margin-block: 6.25rem;
  display: grid;
}

@media screen and (min-width: 1000px) {
  .empty-state {
    margin-block: 12.5rem;
  }
}

@media screen and (min-width: 1400px) {
  .empty-state {
    margin-block: 15.625rem;
  }
}

.shopify-section--announcement-bar, .shopify-section--header {
  transform: translateY(calc(((var(--announcement-bar-height, 0px)  + var(--header-height, 0px)) * -1) * var(--hide-header-group, 0)));
  transition: transform .25s ease-in-out;
}

video-media, model-media {
  border-radius: inherit;
  aspect-ratio: var(--aspect-ratio, var(--default-aspect-ratio));
  width: 100%;
  display: block;
  position: relative;
  container: media / inline-size;
}

@supports not (aspect-ratio: 1) {
  :is(video-media, model-media):before {
    content: "";
    padding-block-end: calc(100% / (var(--aspect-ratio, var(--default-aspect-ratio))));
    display: block;
  }

  :is(video-media, model-media) > * {
    height: 100%;
    top: 0;
    left: 0;
    position: absolute !important;
  }
}

@container media (width >= 32rem) {
  :is(video-media, model-media)::part(play-button) {
    width: 4rem;
    height: 4rem;
  }
}

video-media {
  --default-aspect-ratio: 16 / 9;
}

video-media[host] {
  align-items: center;
  display: grid;
}

video-media[show-play-button]:not([loaded]), video-media:not([autoplay]) {
  cursor: pointer;
}

video-media > :is(video, iframe, img) {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transition: opacity .2s ease-in-out;
}

video-media > video[controls] {
  pointer-events: auto;
}

video-media > :-webkit-any(video:not(:-webkit-full-screen), img) {
  object-fit: cover;
  object-position: center;
  margin: 0 !important;
}

video-media > :is(video:not(:fullscreen), img) {
  object-fit: cover;
  object-position: center;
  margin: 0 !important;
}

video-media > :is(iframe, img) {
  position: absolute;
  inset: 0;
}

video-media:not([loaded]) > :is(video, iframe), video-media[loaded] > img, video-media[loaded]::part(play-button) {
  opacity: 0;
  pointer-events: none;
}

video-media[suspended] ~ * {
  pointer-events: none;
}

video-media::part(play-button) {
  z-index: 1;
  will-change: scale;
  transition: scale .2s ease-in-out, opacity .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

video-media:hover::part(play-button) {
  scale: 1.1;
}

model-media {
  --default-aspect-ratio: 1;
}

model-media model-viewer, model-media .shopify-model-viewer-ui {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

@media screen and (max-width: 699px) {
  .order-summary__header, .order-summary__body td:nth-child(n+2), .order-summary__footer td:first-child {
    display: none;
  }

  .order-summary__body td {
    border-width: 0;
  }

  .order-summary__body tr:last-child td {
    border-block-end-width: 1px;
  }

  .order-summary__body tr:first-child td {
    padding-block-start: 0;
  }

  .order-summary__body tr:last-child td {
    padding-block-end: calc(var(--table-cell-padding-block) * 2);
  }
}

.line-item {
  --line-item-media-width: 4.375rem;
  align-items: center;
  column-gap: 1.5rem;
  display: flex;
}

.line-item__media {
  width: var(--line-item-media-width);
  flex-shrink: 0;
}

@media screen and (min-width: 700px) {
  .line-item {
    --line-item-media-width: 7.5rem;
  }
}

.discount-badge {
  background: rgb(var(--on-sale-badge-background));
  color: rgb(var(--on-sale-badge-text));
  align-items: center;
  column-gap: .5rem;
  padding: .125rem .5rem;
  display: flex;
}

.shipping-estimator__form {
  gap: var(--fieldset-gap);
  display: grid;
}

@media screen and (min-width: 700px) {
  .shipping-estimator__form {
    flex-wrap: wrap;
    display: flex;
  }

  .shipping-estimator__form .select {
    max-width: 15rem;
  }
}

.pagination {
  border-block-end-width: 1px;
  grid-auto-rows: minmax(0, 2.8125rem);
  grid-auto-columns: minmax(0, 2.625rem);
  grid-auto-flow: column;
  justify-self: center;
  display: inline-grid;
}

.pagination__link {
  place-content: center;
  display: grid;
  position: relative;
}

.pagination__link[aria-current="page"] {
  box-shadow: 0 1px, inset 0 -2px;
}

@media screen and (min-width: 700px) {
  .pagination {
    grid-auto-columns: minmax(0, 3.75rem);
  }
}

.pswp {
  --pswp-bg: var(--background-primary);
  --pswp-root-z-index: 100000;
  width: 100%;
  height: 100%;
  z-index: var(--pswp-root-z-index);
  touch-action: none;
  opacity: .003;
  contain: layout style size;
  -webkit-tap-highlight-color: #0000;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.pswp:focus {
  outline: 0;
}

.pswp--open {
  display: block;
}

.pswp img {
  max-width: none;
}

.pswp, .pswp__bg {
  will-change: opacity;
  transform: translateZ(0);
}

.pswp__bg {
  opacity: .005;
  background: rgb(var(--pswp-bg));
}

.pswp, .pswp__scroll-wrap {
  overflow: hidden;
}

.pswp__scroll-wrap, .pswp__bg, .pswp__container, .pswp__item, .pswp__img, .pswp__zoom-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img, .pswp__zoom-wrap {
  width: auto;
  height: auto;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
  cursor: zoom-in;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
  cursor: grab;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: grabbing;
}

.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img, .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active, .pswp__img {
  cursor: zoom-out;
}

.pswp__container, .pswp__img, .pswp__button, .pswp__counter {
  -webkit-user-select: none;
  user-select: none;
}

.pswp__item {
  z-index: 1;
  overflow: hidden;
}

.pswp__hidden {
  display: none !important;
}

.pswp .pswp__hide-on-close {
  opacity: .005;
  will-change: opacity;
  z-index: 10;
  pointer-events: none;
  transition: opacity .3s cubic-bezier(.4, 0, .22, 1), transform .3s cubic-bezier(.4, 0, .22, 1);
  transform: translateY(15px);
}

.pswp--ui-visible .pswp__hide-on-close {
  opacity: 1;
  pointer-events: auto;
  transition-delay: .3s;
  transform: translateY(0);
}

.pswp__top-bar {
  width: 100%;
  z-index: 10;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: .75rem;
  display: flex;
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  pointer-events: none !important;
}

.pswp__top-bar > .circle-button {
  pointer-events: auto;
  transition: transform .2s ease-in-out;
}

@media screen and (pointer: fine) {
  .pswp__top-bar > .circle-button:hover {
    transform: scale(1.1);
  }
}

.pswp__preloader {
  display: none;
}

@media screen and (min-width: 700px) {
  .pswp__top-bar {
    column-gap: 1.125rem;
  }
}

.range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.range::-webkit-slider-thumb {
  height: .625rem;
  width: .625rem;
  background: rgb(var(--text-color));
  cursor: pointer;
  z-index: 1;
  box-shadow: none;
  border-radius: 100%;
  margin-block-start: -.25rem;
  position: relative;
}

.range::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: rgb(var(--text-color) / .12);
  border: none;
  border-radius: 2px;
}

.range::-moz-range-thumb {
  height: .625rem;
  width: .625rem;
  background: rgb(var(--text-color));
  cursor: pointer;
  border: none;
  border-radius: 100%;
}

.range::-moz-range-progress, .range::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  border: none;
  border-radius: 2px;
}

.range::-moz-range-progress {
  background-color: rgba(var(--text-color));
}

.range::-moz-range-track {
  background-color: rgb(var(--border-color));
}

@media not screen and (pointer: fine) {
  .range::-webkit-slider-thumb {
    height: 1rem;
    width: 1rem;
    margin-block-start: -.4375rem;
  }

  .range::-moz-range-thumb {
    height: 1rem;
    width: 1rem;
  }
}

.range-group {
  height: 2px;
  background: linear-gradient(to var(--transform-origin-end), rgb(var(--text-color) / .12) var(--range-min), rgb(var(--text-color)) var(--range-min), rgb(var(--text-color)) var(--range-max), rgb(var(--text-color) / .12) var(--range-max));
  border-radius: 2px;
}

.range-group .range {
  pointer-events: none;
  height: 2px;
  vertical-align: top;
}

.range-group .range::-webkit-slider-runnable-track {
  background: none;
}

.range-group .range::-webkit-slider-thumb {
  pointer-events: auto;
}

.range-group .range::-moz-range-progress, .range-group .range::-moz-range-track {
  background: none;
}

.range-group .range::-moz-range-thumb {
  pointer-events: auto;
}

.range-group .range:last-child {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.input-group {
  align-items: center;
  column-gap: .75rem;
  display: flex;
}

.input-prefix {
  min-width: 0;
  border-radius: min(.625rem, var(--button-border-radius));
  border: 1px solid rgb(var(--text-color) / .12);
  flex: 1 0 0;
  justify-content: space-between;
  align-items: center;
  padding: .4375rem;
  display: flex;
}

.input-prefix:focus-within {
  border-color: rgb(var(--text-color));
  box-shadow: inset 0 0 0 1px rgb(var(--text-color));
}

.input-prefix > .field {
  -webkit-appearance: textfield;
  appearance: textfield;
  min-width: 0;
  width: 100%;
  text-align: end;
  background: none;
  border: none;
  margin-inline-start: .625rem;
  padding: 0;
}

.input-prefix > .field:focus {
  outline: none;
}

.input-prefix > .field::-webkit-outer-spin-button, .input-prefix > .field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.price-range {
  padding-block-start: .25rem;
  display: block;
}

.price-range > .range-group {
  margin-block-end: 1.125rem;
  position: relative;
}

@media not screen and (pointer: fine) {
  .price-range {
    padding-block-start: .625rem;
  }
}

.product-card {
  scroll-snap-align: start;
  grid-template-columns: minmax(0, 1fr);
  align-content: start;
  gap: 1.25rem;
  display: grid;
  position: relative;
}

.product-card__figure {
  position: relative;
}

.product-card__figure > .badge-list {
  z-index: 1;
  position: absolute;
  inset-block-start: .25rem;
  inset-inline-start: .25rem;
}

.product-card__media {
  isolation: isolate;
  display: block;
}

.product-card__image {
  object-fit: contain;
  object-position: center;
  margin-inline: auto;
  transition: opacity .1s ease-in-out;
}

.product-card__image--secondary {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.product-card__quick-add-button {
  background: rgb(var(--background-secondary));
  color: rgb(var(--text-color));
  z-index: 1;
  padding: .625rem;
  position: absolute;
  inset-block-end: .5rem;
  inset-inline-end: .5rem;
}

@media screen and (pointer: fine) {
  .product-card__quick-add-button {
    opacity: 0;
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
    transform: translateY(5px);
  }

  .product-card__quick-add-button :is(svg) {
    transition: transform .2s ease-in-out;
  }

  .product-card__quick-add-button:hover svg {
    transform: rotateZ(90deg);
  }

  .product-card:hover .product-card__quick-add-button {
    opacity: 1;
    transform: translateY(0);
  }
}

.product-card__info {
  text-align: center;
  align-content: start;
  justify-items: center;
  gap: .75rem;
  display: grid;
}

.product-card__info :is(.price-list, .rating-badge) {
  justify-content: center;
}

.product-card__info .color-swatch {
  --swatch-size: 1.375rem;
}

@media screen and (pointer: fine) {
  .product-card__image--secondary {
    opacity: 0;
    mix-blend-mode: plus-lighter;
    display: block;
  }

  .product-card__figure:has(.product-card__image--secondary):hover > .product-card__media .product-card__image--primary {
    opacity: 0;
  }

  .product-card__figure:has(.product-card__image--secondary):hover > .product-card__media .product-card__image--secondary {
    opacity: 1;
  }
}

@media screen and (min-width: 700px) {
  .product-card__figure > .badge-list {
    inset-block-start: .5rem;
    inset-inline-start: .5rem;
  }
}

.horizontal-product-card {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  align-items: center;
  column-gap: 1.5rem;
  display: flex;
  position: relative;
  container: horizontal-product-card / inline-size;
}

.horizontal-product-card__figure {
  width: 6.25rem;
  flex-shrink: 0;
}

.horizontal-product-card__info {
  flex-grow: 1;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-columns: auto;
  justify-items: start;
  gap: .625rem;
  display: grid;
}

.\@large\:horizontal-product-card__button {
  display: none;
}

@media screen and (min-width: 700px) {
  .horizontal-product-card {
    column-gap: 2rem;
  }
}

@container horizontal-product-card (width >= 450px) {
  .horizontal-product-card__info {
    grid-auto-flow: column;
    align-items: center;
  }

  .\@narrow\:horizontal-product-card__button {
    display: none;
  }

  .\@large\:horizontal-product-card__button {
    display: block;
  }
}

.product-list {
  --product-list-max-items-per-row-allowed: 2;
  --product-list-calculated-items-per-row: min(var(--product-list-max-items-per-row-allowed), var(--product-list-items-per-row));
  --product-list-default-row-gap: 2.1875rem;
  --product-list-default-column-gap: .625rem;
  --product-list-calculated-row-gap: clamp(var(--section-vertical-spacing) / 4, var(--product-list-default-row-gap) * var(--product-list-vertical-spacing-factor, 1), var(--section-vertical-spacing) * 2);
  --product-list-calculated-column-gap: clamp(var(--container-gutter) / 4, var(--product-list-default-column-gap) * var(--product-list-horizontal-spacing-factor, 1), var(--container-gutter) * 2);
  --product-list-card-width: calc(100% / var(--product-list-calculated-items-per-row)  - var(--product-list-calculated-column-gap, 0px) * (var(--product-list-calculated-items-per-row)  - 1) / var(--product-list-calculated-items-per-row));
  grid: auto / repeat(auto-fit, var(--product-list-card-width));
  align-items: start;
  column-gap: var(--product-list-calculated-column-gap);
  row-gap: var(--product-list-calculated-row-gap);
  display: grid;
}

.product-list--carousel {
  --product-list-default-column-gap: 1.25rem;
  grid: auto / auto-flow var(--product-list-card-width);
  justify-content: safe center;
}

@media screen and (max-width: 699px) {
  .product-list:not(.product-list--carousel) {
    margin-inline: -.625rem;
  }

  .product-list--carousel {
    --product-list-card-width: min(300px, 65%);
  }
}

@media screen and (min-width: 700px) {
  .product-list {
    --product-list-max-items-per-row-allowed: 3;
    --product-list-default-row-gap: 3rem;
    --product-list-default-column-gap: 1.5rem;
  }

  .product-list--compact {
    --product-list-max-items-per-row-allowed: 4;
  }
}

@media screen and (min-width: 1000px) {
  .product-list {
    --product-list-max-items-per-row-allowed: 4;
    --product-list-default-row-gap: 4rem;
    --product-list-default-column-gap: 3rem;
  }

  .product-list--compact {
    --product-list-max-items-per-row-allowed: 5;
  }

  .product-list--carousel {
    scroll-snap-type: x mandatory;
  }
}

@media screen and (min-width: 1150px) {
  .product-list {
    --product-list-max-items-per-row-allowed: var(--product-list-items-per-row);
  }
}

@media screen and (min-width: 1400px) {
  .product-list {
    --product-list-default-row-gap: 4rem;
    --product-list-default-column-gap: 3.75rem;
  }
}

.quick-buy-modal {
  --modal-body-max-width: 55.625rem;
}

.quick-buy-modal::part(content) {
  position: relative;
}

.quick-buy-modal__content {
  grid-template-columns: minmax(0, 1fr);
  display: grid;
}

.quick-buy-modal__close-button {
  position: absolute;
  inset-block-start: 1.875rem;
  inset-inline-end: 1.875rem;
}

.quick-buy-modal__view-more {
  margin-block-start: 2rem;
  display: inline-block;
}

@media screen and (max-width: 699px) {
  .quick-buy-modal::part(body) {
    padding-inline: 0;
  }

  .quick-buy-modal__gallery-wrapper, .quick-buy-modal__info-wrapper {
    padding-inline-start: var(--modal-body-padding-inline);
    padding-inline-end: var(--modal-body-padding-inline);
  }

  .quick-buy-modal__gallery-wrapper .price-list {
    justify-content: center;
  }

  .quick-buy-modal__gallery-wrapper .price-list .h6 {
    font-size: .6875rem;
  }

  .quick-buy-modal__mobile-info {
    margin-block-start: 1.25rem;
  }

  .quick-buy-modal__info-wrapper {
    border-block-start-width: 1px;
    margin-block-start: 1.25rem;
  }

  .quick-buy-modal__info-wrapper .product-info__block-item:is([data-block-type="vendor"], [data-block-type="title"], [data-block-type="price"], [data-block-type="rating"], [data-block-type="separator"]), .quick-buy-modal__info-wrapper .product-info__block-group[data-group-type="text-with-rating"] {
    display: none;
  }

  .quick-buy-modal__info-wrapper .product-info__block-item[data-block-type="variant-picker"] {
    --product-info-gap: 1.25rem;
  }

  .quick-buy-modal__info-wrapper .product-info__block-item[data-block-type="buy-buttons"] {
    --product-info-gap: 1.5rem;
  }
}

@media screen and (min-width: 700px) {
  .quick-buy-modal {
    --modal-body-padding-inline: 3.125rem;
    --modal-body-padding-block: 3.125rem;
  }

  .quick-buy-modal::part(header) {
    display: none;
  }

  .quick-buy-modal__content {
    grid-template-columns: minmax(0, .9fr) minmax(0, 1fr);
    align-items: center;
    column-gap: 2.5rem;
    display: grid;
  }

  .quick-buy-modal__content .product-info {
    top: unset;
    position: relative;
  }
}

.prose {
  overflow-wrap: anywhere;
}

.prose :first-child, .prose .sr-only:first-child + * {
  margin-block-start: 0 !important;
}

.prose :last-child {
  margin-block-end: 0 !important;
}

.prose :where(:not(meta, span) + *) {
  margin-block-start: 1rem;
}

.prose :where(* + span:not(:empty)) {
  display: inline-block;
}

.prose :is(.link, img) {
  display: inline-block;
}

.prose :is(.link + .link) {
  margin-inline-start: .75rem;
}

.prose :is(.button, .button-group:has(.button)) {
  margin-block-start: 1.5rem;
}

.prose :is(p img:not([style*="float"]):only-child, div img:not([style*="float"]):only-child, figure, video, object, blockquote, table) {
  margin-block: 1.6rem;
}

.prose :is(iframe[src*="youtube"], iframe[src*="youtu.be"], iframe[src*="vimeo"]) {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
}

.prose .play-button {
  margin: 2rem auto;
  display: block;
}

.prose :is(ol, ul) :is(ol, ul) {
  margin-block-start: 0 !important;
}

.prose :is(ol, ul) {
  row-gap: .4em;
  display: grid;
}

.prose figcaption {
  font-style: italic;
  font-size: var(--text-sm);
  color: rgb(var(--text-color) / .65);
  margin-block-start: .5em;
}

.prose blockquote {
  border-inline-start-width: 3px;
  padding: .375rem 0 .375rem 1rem;
  font-size: 1.15rem;
  line-height: 1.75;
}

@media screen and (min-width: 1000px) {
  .prose:not(.prose--tight) :where(:not(meta, span) + *) {
    margin-block-start: 1.25rem;
  }

  .prose:not(.prose--tight) :is(.button, .button-group:has(.button)) {
    margin-block-start: 2rem;
  }

  .prose:not(.prose--tight) .play-button {
    margin-block: 2.5rem;
  }

  .prose:not(.prose--tight) blockquote {
    margin-inline-start: 2rem;
    padding-inline-start: 2rem;
  }
}

.link, .prose a:not(.h1, .h2, .h3, .h4, .h5, .h6, .button, .link-faded, .link-faded-reverse) {
  text-underline: none;
  background: linear-gradient(to right, currentColor, currentColor) 0 min(100%, 1.35em) / 100% 1px no-repeat;
  transition: background-size .3s ease-in-out;
}

@media screen and (pointer: fine) {
  :is(.link, .prose a:not(.h1, .h2, .h3, .h4, .h5, .h6, .button, .link-faded, .link-faded-reverse)):hover {
    background-size: 0 1px;
  }
}

.link-reverse {
  text-underline: none;
  background: linear-gradient(to right, currentColor, currentColor) 0 min(100%, 1.35em) / 0 1px no-repeat;
  transition: background-size .3s ease-in-out;
}

@media screen and (pointer: fine) {
  .link-reverse:hover {
    background-size: 100% 1px;
  }
}

.link-faded {
  color: rgb(var(--text-color) / .65);
  transition: color .2s ease-in-out;
}

@media screen and (pointer: fine) {
  .link-faded:hover {
    color: rgb(var(--text-color));
  }
}

.link-faded-reverse {
  transition: color .2s ease-in-out;
}

@media screen and (pointer: fine) {
  .link-faded-reverse:hover {
    color: rgb(var(--text-color) / .65);
  }
}

table {
  --table-cell-padding-block: .75rem;
  --table-cell-padding-inline: .5rem;
  --table-foot-cell-padding-block: .75rem;
  --table-foot-cell-padding-inline: .5rem;
  width: 100%;
}

th {
  text-align: start;
}

:where(td, th):not(:empty) {
  border-block-width: 1px;
}

:where(th):not(:empty) {
  border-block-start-width: 0;
}

td:not(:empty) + td {
  border-inline-start-width: 1px;
}

th, td {
  padding: var(--table-cell-padding-block) var(--table-cell-padding-inline);
}

:is(th, td):first-child, :is(th, td):empty + td {
  padding-inline-start: 0;
}

:is(th, td):last-child {
  padding-inline-end: 0;
}

th {
  opacity: .6;
  line-height: 1.7;
  font-size: var(--text-h6);
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-style: var(--heading-font-style);
  letter-spacing: var(--heading-letter-spacing);
  text-transform: var(--heading-text-transform);
  padding-block: 0 .625rem;
}

tfoot td {
  padding: var(--table-foot-cell-padding-block) var(--table-foot-cell-padding-inline);
}

.js tr[onclick] {
  cursor: pointer;
  transition: background .2s;
}

.js tr[onclick]:hover {
  background: rgb(var(--text-color) / .05);
}

@media screen and (min-width: 700px) {
  table {
    --table-cell-padding-block: 1rem;
    --table-cell-padding-inline: .75rem;
    --table-foot-cell-padding-block: 1rem;
    --table-foot-cell-padding-inline: .75rem;
  }
}

.table--reduce-border :is(td) + td {
  border-inline-start-width: 0;
}

.table--lg {
  --table-cell-padding-block: 1.5rem;
  --table-cell-padding-inline: .5rem;
}

@media screen and (min-width: 700px) {
  .table--lg {
    --table-cell-padding-block: 2rem;
    --table-cell-padding-inline: .75rem;
  }
}

.social-media--list {
  align-items: center;
  gap: var(--social-media-list-gap, .75rem 2rem);
  flex-wrap: wrap;
  display: flex;
}

.social-media--list .social-media__item {
  opacity: .65;
  transition: opacity .2s, color .2s;
}

.social-media--list svg {
  width: var(--social-media-icon-size, 1.25rem);
}

@media screen and (pointer: fine) {
  .social-media--list .social-media__item:hover {
    opacity: 1;
  }
}

.social-media--block {
  grid-template-columns: repeat(auto-fit, minmax(0, .33fr));
  gap: 1px;
  display: grid;
}

.social-media--block:has(:nth-child(4)) {
  grid-template-columns: repeat(auto-fit, minmax(0, .25fr));
}

.social-media--block .social-media__item {
  width: 100%;
  outline: 1px solid rgb(var(--border-color));
  z-index: 0;
  transition: background .15s, color .15s, outline .15s, z-index .15s;
}

.social-media--block a {
  padding-block: .625rem;
  display: block;
}

.social-media--block svg {
  margin-inline: auto;
}

@media screen and (pointer: fine) {
  .social-media--block .social-media__item:hover {
    background: rgb(var(--branding-background));
    outline-color: rgb(var(--branding-background));
    color: rgb(var(--branding-color));
    z-index: 1;
  }
}

@media not screen and (pointer: fine) {
  .social-media--block .social-media__item {
    background: rgb(var(--branding-background));
    outline-color: rgb(var(--branding-background));
    color: rgb(var(--branding-color));
  }
}

@media screen and (min-width: 700px) {
  .social-media--block {
    grid-template-columns: repeat(auto-fit, minmax(0, 3.75rem));
  }
}

.branding-colors--facebook {
  --branding-background: 24 119 242;
  --branding-color: 255 255 255;
}

.branding-colors--twitter {
  --branding-background: 29 161 242;
  --branding-color: 255 255 255;
}

.branding-colors--instagram {
  --branding-background: 195 42 163;
  --branding-color: 255 255 255;
}

.branding-colors--pinterest {
  --branding-background: 189 8 28;
  --branding-color: 255 255 255;
}

.branding-colors--youtube {
  --branding-background: 255 0 0;
  --branding-color: 255 255 255;
}

.branding-colors--tiktok {
  --branding-background: 1 1 1;
  --branding-color: 255 255 255;
}

.branding-colors--vimeo {
  --branding-background: 26 183 234;
  --branding-color: 255 255 255;
}

.branding-colors--linkedin {
  --branding-background: 10 102 194;
  --branding-color: 255 255 255;
}

.branding-colors--whatsapp {
  --branding-background: 79 206 93;
  --branding-color: 255 255 255;
}

.branding-colors--snapchat {
  --branding-background: 255 252 0;
  --branding-color: 0 0 0;
}

.branding-colors--tumblr {
  --branding-background: 54 70 93;
  --branding-color: 255 255 255;
}

.branding-colors--wechat {
  --branding-background: 9 184 62;
  --branding-color: 255 255 255;
}

.branding-colors--reddit {
  --branding-background: 255 69 0;
  --branding-color: 255 255 255;
}

.branding-colors--line {
  --branding-background: 6 199 85;
  --branding-color: 255 255 255;
}

.branding-colors--spotify {
  --branding-background: 30 215 96;
  --branding-color: 255 255 255;
}

.branding-colors--21buttons {
  --branding-background: 0 0 0;
  --branding-color: 255 255 255;
}

.branding-colors--email {
  --branding-background: var(--text-primary);
  --branding-color: var(--background);
}

#shopify-product-reviews {
  --spr-star-list-gap: .125rem;
  --spr-star-size: 1rem;
  --spr-star-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 15'%3E%3Cpath d='m8 0 2.116 5.088 5.492.44-4.184 3.584 1.278 5.36L8 11.6l-4.702 2.872 1.278-5.36L.392 5.528l5.492-.44L8 0Z' fill='currentColor'/%3E%3C/svg%3E");
  --spr-star-color: var(--star-color);
  margin: 0 !important;
}

#shopify-product-reviews:before, #shopify-product-reviews:after, #shopify-product-reviews :before, #shopify-product-reviews :after {
  display: none !important;
}

#shopify-product-reviews .spr-container {
  max-width: var(--container-lg-max-width);
  border-width: 0;
  margin-inline: auto;
  padding: 0;
}

#shopify-product-reviews :is(.spr-button-primary, .spr-summary-actions-newreview, .spr-review-reportreview, .spr-review-reply-shop) {
  float: none;
}

#shopify-product-reviews :is(.spr-summary-caption, .spr-summary-starrating, .spr-form-title) {
  display: none;
}

#shopify-product-reviews .spr-summary {
  text-align: start;
}

#shopify-product-reviews .spr-summary-actions-newreview {
  margin-block-end: 1.75rem;
}

#shopify-product-reviews :has(.spr-reviews:empty) .spr-summary-actions-newreview {
  margin-block-end: 0;
}

#shopify-product-reviews .spr-form {
  border: none;
  margin: 0;
  padding: 0;
}

#shopify-product-reviews :where(.spr-starrating, .spr-starratings) {
  gap: var(--spr-star-list-gap);
  display: flex;
}

#shopify-product-reviews .spr-icon {
  width: var(--spr-star-size);
  height: var(--spr-star-size);
  opacity: 1;
  background-color: rgb(var(--spr-star-color));
  -webkit-mask-image: var(--spr-star-image);
  mask-image: var(--spr-star-image);
  flex-shrink: 0;
  display: block;
  inset-block-start: 0;
}

#shopify-product-reviews .spr-icon-star-empty {
  background-color: rgb(var(--spr-star-color) / .4);
}

#shopify-product-reviews .spr-form-input.spr-starrating:focus-within .spr-icon {
  background-color: rgb(var(--spr-star-color));
}

#shopify-product-reviews .spr-form-input.spr-starrating:focus-within .spr-icon:focus ~ .spr-icon {
  background-color: rgb(var(--spr-star-color) / .4);
}

#shopify-product-reviews .spr-content {
  row-gap: 1.875rem;
  display: grid;
}

#shopify-product-reviews :is(.spr-reviews, .spr-review, .spr-review-header-starratings, .spr-review-content, .spr-review-header, .spr-review-reply) {
  margin: 0;
  padding: 0;
}

#shopify-product-reviews .spr-reviews:not(:empty) {
  gap: 2rem;
  display: grid !important;
}

#shopify-product-reviews .spr-review {
  border: none;
  gap: .75rem;
  display: grid;
  position: relative;
}

#shopify-product-reviews .spr-review-header {
  flex-wrap: wrap;
  align-items: center;
  gap: .25rem 1rem;
  display: flex;
}

#shopify-product-reviews .spr-review-header-byline {
  width: 100%;
  color: rgb(var(--text-color) / .65);
  font-style: normal;
  font-size: var(--text-xs);
  margin: 0;
  position: absolute;
  inset-block-end: 0;
}

#shopify-product-reviews .spr-review-header-byline :is(strong) {
  font-weight: normal;
}

#shopify-product-reviews .spr-review-content-body {
  font-size: var(--text-base);
}

#shopify-product-reviews .spr-review-footer {
  justify-content: end;
  display: flex;
}

#shopify-product-reviews .spr-review-reportreview {
  opacity: .65;
  transition: opacity .2s;
}

#shopify-product-reviews .spr-review-reportreview:hover {
  opacity: 1;
}

#shopify-product-reviews .spr-review-reply {
  padding: .625rem;
}

#shopify-product-reviews .spr-review-reply-body {
  margin-block-end: .25rem;
}

#shopify-product-reviews .spr-pagination {
  border-block-start: none;
  padding: 0;
}

#shopify-product-reviews .spr-pagination-page a {
  padding: .25rem .5rem;
  display: inline-block;
}

#shopify-product-reviews :is(.spr-pagination-prev, .spr-pagination-next) {
  position: static;
}

.accordion .spr-header-title {
  display: none;
}

.color-swatch {
  --swatch-offset: 2px;
  --swatch-size: 2.125rem;
  --swatch-border-size: 1px;
  --swatch-disabled-strike-color: rgb(var(--border-color));
  width: var(--swatch-size);
  height: var(--swatch-size);
  padding: var(--swatch-offset);
  border: var(--swatch-border-size) solid transparent;
  background: content-box var(--swatch-background) no-repeat center / cover;
  place-items: center;
  display: grid;
  position: relative;
}

:disabled + .color-swatch:before, .color-swatch.is-disabled:before {
  content: "";
  margin: var(--swatch-disabled-margin, var(--swatch-offset));
  background: linear-gradient(to bottom right, transparent calc(50% - .5px), var(--swatch-disabled-strike-color) calc(50% - .5px) calc(50% + .5px), transparent calc(50% + .5px));
  position: absolute;
  inset: 0;
}

:checked + .color-swatch, .color-swatch.is-selected {
  --swatch-disabled-margin: 0;
  --swatch-disabled-strike-color: currentColor;
  border-color: currentColor;
}

:focus-visible + .color-swatch {
  outline-offset: calc(var(--swatch-offset));
}

.media-swatch {
  --swatch-size: 3rem;
  --swatch-disabled-strike-color: rgb(var(--border-color));
  width: var(--swatch-size);
  height: var(--swatch-size);
  border-radius: min(4px, var(--input-border-radius));
  border-width: 1px;
  display: block;
  position: relative;
}

:checked + .media-swatch, .media-swatch.is-selected {
  --swatch-disabled-strike-color: currentColor;
  border-color: currentColor;
}

:disabled + .media-swatch:before, .media-swatch.is-disabled:before {
  content: "";
  background: linear-gradient(to bottom right, transparent calc(50% - .5px), var(--swatch-disabled-strike-color) calc(50% - .5px) calc(50% + .5px), transparent calc(50% + .5px));
  position: absolute;
  inset: 0;
}

.media-swatch > img {
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

:focus-visible + .media-swatch {
  outline-offset: 2px;
}

@media screen and (min-width: 700px) {
  .media-swatch {
    --swatch-size: 4.25rem;
  }
}

.block-swatch {
  --swatch-border-size: 1px;
  --swatch-padding: calc(var(--input-padding-block)  - var(--swatch-border-size) * 2) .875rem;
  --swatch-disabled-strike-color: rgb(var(--border-color));
  padding: var(--swatch-padding);
  min-width: 2.5rem;
  border-width: var(--swatch-border-size);
  border-radius: var(--input-border-radius);
  color: rgb(var(--text-color) / .65);
  justify-content: center;
  align-items: center;
  column-gap: .875rem;
  display: flex;
  position: relative;
}

.block-swatch.is-disabled {
  color: rgb(var(--text-color) / .5);
}

:checked + .block-swatch, .block-swatch.is-selected {
  --swatch-disabled-strike-color: currentColor;
  color: var(--text-color);
  border-color: currentColor;
}

:disabled + .block-swatch:before, .block-swatch.is-disabled:before {
  content: "";
  background: linear-gradient(to bottom right, transparent calc(50% - .5px), var(--swatch-disabled-strike-color) calc(50% - .5px) calc(50% + .5px), transparent calc(50% + .5px));
  position: absolute;
  inset: 0;
}

:focus-visible + .block-swatch {
  outline-offset: 2px;
}

.block-swatch__color {
  width: 1rem;
  height: 1rem;
  background: var(--swatch-background) center / cover;
}

.content-tabs::part(tab-list) {
  border-block-end: 1px solid rgb(var(--border-color));
  white-space: nowrap;
  grid-auto-flow: column;
  justify-content: start;
  gap: 2.5rem;
  margin-block-end: 1.5rem;
  display: grid;
}

.content-tabs [role="tab"] {
  box-shadow: 0 1px rgb(var(--text-color));
  padding-block-end: .625rem;
  transition: opacity .2s ease-in, box-shadow .2s ease-in;
}

.content-tabs [role="tab"]:not([aria-selected="true"]) {
  opacity: .6;
  box-shadow: 0 1px #0000;
}

.content-tabs--center::part(tab-list) {
  width: max-content;
  margin-inline: auto;
}

.content-tabs--center::part(tab-panel-list) {
  width: min(800px, 100%);
  text-align: center;
  margin-inline: auto;
  display: block;
}

@media screen and (min-width: 1000px) {
  .content-tabs::part(tab-list) {
    gap: 4.375rem;
    margin-block-end: 2rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .features--page-transition {
    opacity: 0;
  }
}

.constrained-image {
  width: var(--image-mobile-max-width, var(--image-max-width));
  max-width: 100%;
}

@media screen and (min-width: 700px) {
  .constrained-image {
    width: var(--image-max-width);
  }
}

.text-with-icon {
  align-items: center;
  gap: .625rem;
  display: flex;
}

.text-with-icon > svg {
  flex-shrink: 0;
}

.icon {
  vertical-align: middle;
  height: auto;
  transition: transform .2s;
  display: block;
}

.group[aria-expanded="true"] .icon.group-expanded\:rotate {
  transform: rotateZ(180deg);
}

[dir="rtl"] .icon--direction-aware {
  scale: -1;
}

.offset-icon {
  --icon-baseline-distance: calc(1em * 1.65 / 2);
  top: var(--icon-baseline-distance);
  position: relative;
  translate: 0 -50%;
}

@supports (top: 1lh) {
  .offset-icon {
    top: .5lh;
  }
}

.price-list {
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: .5rem;
  display: flex;
}

.price-list--product {
  column-gap: 1rem;
}

.localization-selectors {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: .75rem 1.75rem;
  display: flex;
}

.localization-selectors__separator {
  width: 1px;
  height: .5rem;
  background: rgb(var(--border-color));
}

.localization-selectors__separator:last-child {
  display: none;
}

.localization-toggle {
  align-items: center;
  column-gap: .625rem;
  display: flex;
}

.localization-toggle > svg {
  transition: transform .2s;
}

.localization-toggle[aria-expanded="true"] > svg {
  transform: rotate(180deg);
}

.country-flag {
  width: 20px;
  height: 15px;
}

.payment-methods {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.badge {
  width: max-content;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-style: var(--heading-font-style);
  letter-spacing: var(--heading-letter-spacing);
  text-transform: uppercase;
  align-items: center;
  padding: .0625rem .3125rem;
  font-size: .625rem;
  display: flex;
}

.badge--on-sale {
  background: rgb(var(--on-sale-badge-background));
  color: rgb(var(--on-sale-badge-text));
}

.badge--sold-out {
  background: rgb(var(--sold-out-badge-background));
  color: rgb(var(--sold-out-badge-text));
}

.badge--custom {
  background: rgb(var(--custom-badge-background));
  color: rgb(var(--custom-badge-text));
}

@media screen and (min-width: 700px) {
  .badge {
    font-size: .6875rem;
  }
}

.badge-list {
  flex-wrap: wrap;
  gap: .25rem;
  display: flex;
}

.badge-list--vertical {
  flex-direction: column;
}

.feature-badge {
  font-size: var(--text-xs);
  align-items: center;
  column-gap: .5rem;
  padding: .375rem .5rem;
  display: inline-flex;
}

.feature-badge-list {
  flex-wrap: wrap;
  gap: .5rem;
  display: flex;
}

.rating-badge {
  flex-wrap: wrap;
  align-items: center;
  column-gap: .5rem;
  line-height: normal;
  display: flex;
}

.rating-badge__stars {
  column-gap: .125rem;
  display: flex;
  position: relative;
  top: -.5px;
}

@media (pointer: fine) {
  .is-scrollable[allow-drag] {
    cursor: grab;
  }

  .is-scrollable[allow-drag]:active {
    cursor: grabbing;
    -webkit-user-select: none;
    user-select: none;
    scroll-snap-type: none;
  }
}

.text-with-rating {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: .75rem .5rem;
  display: flex;
}

.inventory {
  row-gap: .5rem;
  font-style: italic;
  display: grid;
}

.progress-bar {
  height: 2px;
  background: rgb(var(--text-primary) / .1);
  display: block;
  position: relative;
}

.progress-bar:before {
  content: "";
  height: 100%;
  width: 100%;
  transform: scaleX(var(--progress, 0));
  transform-origin: var(--transform-origin-start);
  background: currentColor;
  transition: transform .45s ease-in-out;
  display: block;
}

[data-tooltip] {
  --tooltip-text-color: var(--text-color);
  --tooltip-background: var(--background);
  position: relative;
}

@media screen and (pointer: fine) {
  [data-tooltip]:after {
    content: attr(data-tooltip);
    inset-block-end: calc(100% + var(--tooltip-offset, .5rem));
    width: max-content;
    max-width: 15rem;
    border-radius: min(1.5rem, var(--button-border-radius));
    background: rgb(var(--tooltip-text-color));
    color: rgb(var(--tooltip-background));
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    justify-self: center;
    padding: .125rem .5rem;
    font-size: .6875rem;
    transition: opacity .1s ease-in-out, visibility .1s ease-in-out, scale .1s ease-in-out;
    position: absolute;
    left: 50%;
    translate: -50%;
    scale: .9;
  }

  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
    scale: 1;
  }
}

.separator-dot {
  --dot-size: .25rem;
  width: var(--dot-size);
  height: var(--dot-size);
  background: currentColor;
  border-radius: 100%;
}

.animated-plus {
  --animated-plus-size: .625rem;
  width: var(--animated-plus-size);
  height: var(--animated-plus-size);
  display: block;
  position: relative;
}

.animated-plus:before, .animated-plus:after {
  content: "";
  background-color: currentColor;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-90deg);
}

.animated-plus:before {
  width: var(--animated-plus-size);
  height: 1px;
  opacity: 1;
}

.animated-plus:after {
  width: 1px;
  height: var(--animated-plus-size);
}

.group[aria-expanded="true"] .animated-plus.group-expanded\:rotate:before {
  opacity: 0;
}

.group[aria-expanded="true"] .animated-plus.group-expanded\:rotate:before, .group[aria-expanded="true"] .animated-plus.group-expanded\:rotate:after {
  transform: translate(-50%, -50%)rotate(90deg);
}

@media (pointer: fine) {
  .is-scrollable[allow-drag] {
    cursor: grab;
  }

  .is-scrollable[allow-drag]:active {
    cursor: grabbing;
    -webkit-user-select: none;
    user-select: none;
    scroll-snap-type: none;
  }
}

@media screen and (min-width: 700px) {
  .play-button svg {
    width: 5rem;
    height: 5rem;
  }
}

.quantity-selector {
  --quantity-selector-height: 2.7rem;
  --quantity-selector-input-width: 2.5rem;
  --tooltip-offset: calc(-1 * var(--quantity-selector-height) / 2 + 1rem);
  grid-template-columns: var(--quantity-selector-height) auto var(--quantity-selector-height);
  grid-auto-rows: var(--quantity-selector-height);
  border-width: 1px;
  display: inline-grid;
}

.quantity-selector__button {
  place-content: center;
  display: grid;
}

.quantity-selector__button svg {
  transition: opacity .2s;
}

.quantity-selector__button[disabled] svg, .quantity-selector__button:hover svg {
  opacity: .65;
}

.quantity-selector__input {
  -webkit-appearance: textfield;
  appearance: textfield;
  min-width: var(--quantity-selector-input-width);
  width: var(--quantity-selector-character-count, 1ch);
  text-align: center;
  background: none;
}

.quantity-selector__input:focus {
  outline: none;
}

.quantity-selector__input::-webkit-outer-spin-button, .quantity-selector__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-selector--sm {
  --quantity-selector-height: 2.2rem;
  --quantity-selector-input-width: 2rem;
}

.breadcrumb {
  display: none;
}

.breadcrumb__list {
  align-items: center;
  column-gap: .5rem;
  line-height: 1;
  display: flex;
}

.breadcrumb__list-item + .breadcrumb__list-item:before {
  content: "/";
  color: rgb(var(--text-color) / .65);
  margin-inline-end: .375rem;
  font-size: .625rem;
  display: inline-block;
}

@media screen and (min-width: 1000px) {
  .breadcrumb {
    display: block;
  }
}

.breadcrumb--floating {
  position: absolute;
  inset-block-start: 1.25rem;
  inset-inline-start: var(--container-gutter);
}

.section-spacing .breadcrumb--floating {
  inset-block-start: calc(-1 * var(--section-vertical-spacing)  + 1.25rem);
}

.bordered-box {
  border-width: 1px;
  padding: 2.5rem 1.25rem 1.25rem;
  position: relative;
}

.bordered-box__title {
  inset-block-start: 0;
  width: max-content;
  text-align: center;
  background: rgb(var(--background));
  padding-inline: .5rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1000px) {
  .bordered-box {
    padding: 3rem;
  }
}

.loading-bar {
  height: 2px;
  width: 100%;
  z-index: 2000;
  pointer-events: none;
  mix-blend-mode: exclusion;
  transform-origin: var(--transform-origin-start);
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  transform: scaleX(0);
}

@media screen and (min-width: 700px) {
  .loading-bar {
    height: 3px;
  }
}

@keyframes animateCircularProgress {
  from {
    stroke-dashoffset: var(--stroke-dasharray);
  }

  to {
    stroke-dashoffset: 0;
  }
}

.circular-progress {
  transition: opacity .25s;
  transform: rotate(-90deg);
}

.circular-progress circle:last-child {
  stroke-dasharray: var(--stroke-dasharray);
  stroke-dashoffset: var(--stroke-dasharray);
}

@media (prefers-reduced-motion: no-preference) {
  img[is="image-parallax"] {
    transform: scale(1.3)translateY(-11.5385%);
  }
}

.scroll-margin-offset {
  scroll-margin-block-start: 20px;
}

.share-buttons {
  align-items: center;
  gap: .875rem;
  display: flex;
}

.share-buttons .social-media {
  --social-media-icon-size: 1rem;
  --social-media-list-gap: .75rem;
}

.share-buttons--native:not([hidden]) + .share-buttons {
  display: none;
}

.customer-account-box {
  min-height: calc(100vh - var(--section-vertical-spacing) * 2 - var(--announcement-bar-height, 0px)  - var(--header-height, 0px));
  text-align: center;
  align-items: center;
  display: grid;
}

.customer-account-category {
  border-block-end-width: 1px;
  padding-block-end: .625rem;
}

.customer-account-overview {
  grid-gap: 2.5rem 3rem;
  display: grid;
}

.customer-account-order-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: .5rem;
  display: grid;
}

@media screen and (min-width: 1000px) {
  .customer-account-overview {
    grid-template-columns: minmax(0, 1fr) 14rem;
  }
}

@media screen and (min-width: 1150px) {
  .customer-account-overview {
    grid-template-columns: minmax(0, 1fr) 18rem;
    column-gap: 3.75rem;
  }
}

.customer-address-list {
  --addresses-per-row: 1;
  grid-template-columns: repeat(var(--addresses-per-row), minmax(0, 1fr));
  gap: 3rem 3.75rem;
  display: grid;
}

@media screen and (min-width: 700px) {
  .customer-address-list {
    --addresses-per-row: 2;
  }
}

@media screen and (min-width: 1000px) {
  .customer-address-list {
    --addresses-per-row: 3;
  }
}

.customer-order {
  grid: auto / minmax(0, 1fr);
  gap: 3rem;
  display: grid;
}

.customer-order__details {
  grid-template-columns: minmax(0, 1fr);
  row-gap: 3rem;
  display: grid;
}

.customer-order__address-list {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-content: start;
  gap: 2.5rem 3.75rem;
  display: grid;
}

@media screen and (min-width: 1150px) {
  .customer-order {
    grid: auto / auto-flow minmax(0, 1fr) 14rem;
    column-gap: 3.75rem;
  }
}

@media screen and (min-width: 1400px) {
  .customer-order {
    grid-auto-columns: minmax(0, 1fr) 18rem;
  }
}

.announcement-bar {
  --announcement-bar-gutter: .625rem;
  text-align: center;
  font-size: var(--announcement-bar-font-size);
  place-content: center;
  column-gap: .75rem;
  padding-block: 1em;
  padding-inline-start: max(var(--announcement-bar-gutter), 50% - var(--container-max-width) / 2);
  padding-inline-end: max(var(--announcement-bar-gutter), 50% - var(--container-max-width) / 2);
  display: flex;
}

.announcement-bar__carousel {
  max-width: 35rem;
  flex-grow: 1;
  place-items: center;
  display: grid;
}

.announcement-bar__carousel > * {
  grid-area: 1 / -1;
}

.announcement-bar__carousel > :not(.is-selected) {
  visibility: hidden;
}

@media screen and (min-width: 700px) {
  .announcement-bar {
    --announcement-bar-gutter: var(--container-gutter);
    justify-content: center;
    column-gap: 2.5rem;
  }
}

.before-after {
  --before-after-content-inset-inline: 1.25rem;
  --before-after-content-inset-block: 1rem;
  --before-after-cursor-size: 3.125rem;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  display: grid;
  position: relative;
}

.before-after > * {
  grid-area: 1 / -1;
}

.no-js .before-after {
  --before-after-cursor-position: var(--before-after-initial-cursor-position);
}

.before-after__after-image {
  z-index: 1;
}

.before-after__content-wrapper {
  position: absolute;
}

.before-after__cursor {
  pointer-events: auto;
  filter: drop-shadow(0 1px 1px #0000000d) drop-shadow(0 1px 1px #0000000d);
  z-index: 1;
  display: grid;
  position: relative;
}

.before-after__cursor:before {
  content: "";
  background: rgb(var(--before-after-cursor-background));
  z-index: -1;
  position: absolute;
}

.before-after__cursor svg {
  width: var(--before-after-cursor-size);
  height: var(--before-after-cursor-size);
}

.before-after--horizontal {
  cursor: ew-resize;
}

.before-after--horizontal .before-after__after-image {
  clip-path: inset(0 0 0 var(--before-after-cursor-position, 0%));
}

[dir="rtl"] :is(.before-after--horizontal .before-after__after-image) {
  clip-path: inset(0 var(--before-after-cursor-position, 0%) 0 0);
}

.before-after--horizontal .before-after__before-image .before-after__content-wrapper {
  justify-items: start;
  inset-block-end: var(--before-after-content-inset-block);
  inset-inline-start: var(--before-after-content-inset-inline);
}

.before-after--horizontal .before-after__after-image .before-after__content-wrapper {
  text-align: end;
  justify-items: end;
  inset-block-end: var(--before-after-content-inset-block);
  inset-inline-end: var(--before-after-content-inset-inline);
}

.before-after--horizontal .before-after__cursor {
  justify-self: start;
  place-items: center start;
  inset-inline-start: calc(var(--before-after-cursor-position, 0%)  - var(--before-after-cursor-size) / 2);
}

.before-after--horizontal .before-after__cursor:before {
  height: 100%;
  width: .125rem;
  inset-inline-start: calc(var(--before-after-cursor-size) / 2);
}

.before-after--vertical {
  cursor: ns-resize;
}

.before-after--vertical .before-after__after-image {
  clip-path: inset(var(--before-after-cursor-position, 0%) 0 0 0);
}

.before-after--vertical .before-after__before-image .before-after__content-wrapper {
  justify-items: start;
  inset-block-start: var(--before-after-content-inset-block);
  inset-inline-start: var(--before-after-content-inset-inline);
}

.before-after--vertical .before-after__after-image .before-after__content-wrapper {
  justify-items: start;
  inset-block-end: var(--before-after-content-inset-block);
  inset-inline-start: var(--before-after-content-inset-inline);
}

.before-after--vertical .before-after__cursor {
  align-self: start;
  place-items: start center;
  inset-block-start: calc(var(--before-after-cursor-position, 0%)  - var(--before-after-cursor-size) / 2);
}

.before-after--vertical .before-after__cursor:before {
  height: .125rem;
  width: 100%;
  inset-block-start: calc(var(--before-after-cursor-size) / 2);
}

@media screen and (max-width: 699px) {
  .before-after {
    --before-after-cursor-size: 2.5rem;
  }

  .before-after .button {
    padding: .45rem 1.5rem;
  }
}

@media screen and (min-width: 700px) {
  .before-after {
    --before-after-content-inset-inline: 2.5rem;
    --before-after-content-inset-block: 2rem;
  }

  .before-after__content-wrapper:has(.button) {
    --before-after-content-inset-block: 2.5rem;
  }
}

.blog-tags {
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem 1.5rem;
  display: flex;
}

.article {
  --article-wrapper-offset: 0px;
  --article-wrapper-padding-inline: 0px;
  margin-block-end: var(--section-vertical-spacing);
}

.article__toolbar {
  width: 100vw;
  visibility: hidden;
  z-index: 1;
  padding-block: 1rem;
  transition: transform .2s, visibility .2s;
  position: fixed;
  inset-block-start: var(--sticky-area-height);
  transform: translateY(-100%);
}

.article__toolbar.is-visible {
  visibility: visible;
  transform: translateY(0);
}

.article__toolbar .social-media {
  gap: .75rem 1rem;
}

.article__toolbar .social-media svg {
  width: 1rem;
  height: 1rem;
}

.article__image {
  overflow: hidden;
}

.article__image > img {
  height: var(--article-image-height);
  object-fit: cover;
}

.article__wrapper {
  padding: 1.5rem var(--article-wrapper-padding-inline) 0;
  background: rgb(var(--background));
  gap: 5rem;
  margin-inline-start: max(var(--container-gutter), 50% - var(--container-max-width) / 2 - var(--article-wrapper-padding-inline));
  margin-inline-end: max(var(--container-gutter), 50% - var(--container-max-width) / 2 - var(--article-wrapper-padding-inline));
  display: grid;
  position: relative;
}

.article__image + .article__wrapper {
  margin-block-start: var(--article-wrapper-offset);
}

.article__comments {
  gap: inherit;
  scroll-margin-block-start: calc(var(--sticky-area-height)  + 20px);
  display: grid;
}

.article__footer {
  gap: 2.5rem;
  display: grid;
}

@media screen and (min-width: 700px) {
  .article {
    --article-wrapper-offset: -2.75rem;
    --article-wrapper-padding-inline: 3.125rem;
  }

  .article__wrapper {
    gap: 6.25rem;
    padding-block-start: 2.5rem;
  }

  .article__footer {
    grid: auto / auto-flow minmax(0, 1fr);
    align-items: center;
  }
}

@media screen and (min-width: 1000px) {
  .article {
    --article-wrapper-offset: -4.375rem;
  }
}

.comment {
  gap: 1.125rem;
  display: grid;
}

.comment:not(:first-child) {
  padding-block-start: 2rem;
}

.cart-page {
  align-items: start;
  row-gap: max(4rem, var(--section-vertical-spacing-tight));
  display: grid;
}

.cart-footer {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.cart-recap {
  gap: .5rem;
  display: grid;
}

.cart-recap [type="submit"] {
  margin-block-start: 1rem;
}

@media screen and (min-width: 700px) {
  .cart-footer {
    flex-direction: row;
    gap: 3rem;
  }

  .cart-footer > :not(:only-child) {
    width: min(24rem, 100%);
  }

  .cart-recap {
    text-align: end;
    justify-items: end;
    margin-inline-start: auto;
  }
}

@media screen and (min-width: 1000px) {
  .cart-page {
    row-gap: max(6rem, var(--section-vertical-spacing-tight));
  }
}

.cart-drawer::part(body) {
  padding-block: 0;
}

.cart-drawer:has(.cart-drawer__order-note[open])::part(header), .cart-drawer:has(.cart-drawer__order-note[open])::part(body) {
  opacity: .4;
  pointer-events: none;
  transition: opacity .2s;
}

.cart-drawer:not(:has(.cart-drawer__footer:only-child))::part(footer) {
  padding-block-start: calc(var(--drawer-footer-padding-block)  - .5rem);
}

.cart-drawer .free-shipping-bar {
  font-size: var(--text-sm);
  border-block-end-width: 1px;
  margin-inline-start: calc(-1 * var(--drawer-body-padding-inline));
  margin-inline-end: calc(-1 * var(--drawer-body-padding-inline));
  padding-block: .5rem;
  padding-inline-start: var(--drawer-body-padding-inline);
  padding-inline-end: var(--drawer-body-padding-inline);
}

.cart-drawer__items {
  gap: 1.5rem;
  padding-block-start: var(--drawer-body-padding-block);
  padding-block-end: var(--drawer-body-padding-block);
  display: grid;
}

.cart-drawer__button-price {
  align-items: center;
  display: flex;
}

.cart-drawer__button-price:before {
  content: "";
  width: 3px;
  height: 3px;
  background: currentColor;
  border-radius: 100%;
  margin-inline-end: 1.125rem;
}

.cart-drawer__footer {
  align-items: start;
  gap: 1rem;
  display: grid;
}

.cart-drawer__order-note {
  width: 100%;
  background: rgb(var(--background));
  box-shadow: 1px 0 6px rgb(var(--border-color));
  z-index: 2;
  border-block-start-width: 1px;
  padding-block-start: calc(var(--drawer-footer-padding-block)  - .5rem);
  padding-block-end: var(--drawer-footer-padding-block);
  padding-inline-start: var(--drawer-body-padding-inline);
  padding-inline-end: var(--drawer-body-padding-inline);
  display: none;
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
}

.collection-banner {
  display: block;
  position: relative;
}

.collection-banner .breadcrumb {
  z-index: 2;
}

.collection {
  --collection-gap: 2rem;
  --collection-grid-template: minmax(0, 1fr);
  --collection-sidebar-width: 15rem;
  grid-template-columns: var(--collection-grid-template);
  align-items: start;
  gap: var(--collection-gap);
  margin-block-end: var(--section-vertical-spacing);
  scroll-margin-block-start: calc(var(--collection-toolbar-height, 0px)  + 20px);
  display: grid;
}

.collection:only-child {
  margin-block-start: 1.5rem;
}

.collection__main {
  row-gap: var(--section-stack-gap);
  display: grid;
}

.collection .product-list[collection-mobile-layout="medium"] {
  --product-list-items-per-row: var(--collection-items-per-row-medium);
}

.collection .product-list[collection-mobile-layout="large"] {
  --product-list-items-per-row: var(--collection-items-per-row-large);
}

@media screen and (min-width: 700px) {
  .collection:only-child {
    margin-block-start: 3rem;
  }

  .collection .product-list[collection-desktop-layout="compact"] {
    --product-list-items-per-row: var(--collection-items-per-row-compact);
  }

  .collection .product-list[collection-desktop-layout="compact"] .product-card__info {
    display: none;
  }

  .collection .product-list[collection-desktop-layout="medium"] {
    --product-list-items-per-row: var(--collection-items-per-row-medium);
  }

  .collection .product-list[collection-desktop-layout="large"] {
    --product-list-items-per-row: var(--collection-items-per-row-large);
  }
}

@media screen and (min-width: 1150px) {
  .collection {
    --collection-gap: 3.125rem;
  }
}

.collection-toolbar {
  min-height: 3em;
  box-shadow: 0 1px rgb(var(--border-color)), 0 -1px rgb(var(--border-color));
  background-color: rgb(var(--background));
  z-index: 2;
  display: flex;
  position: sticky;
  inset-block-start: var(--sticky-area-height);
  inset-inline-start: 0;
}

.collection-toolbar .popover {
  --popover-block-offset: 0px;
  inset-inline-end: -1px;
}

.collection-toolbar__button-list {
  flex-grow: 1;
  display: flex;
}

.collection-toolbar__button {
  padding-block: .75rem;
}

.collection-toolbar__button :is(svg) {
  transition: transform .2s;
}

.collection-toolbar__button[aria-expanded="true"] svg {
  transform: rotateZ(180deg);
}

.collection-toolbar__button-container {
  width: 100%;
  position: relative;
}

.collection-toolbar__button-container + .collection-toolbar__button-container {
  border-inline-start-width: 1px;
}

.collection-toolbar__layout-switch-list {
  border-inline-start-width: 1px;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding-inline: 1.25rem;
  display: flex;
}

.collection-toolbar__button {
  opacity: .5;
  transition: opacity .2s;
}

.collection-toolbar__button:hover, .collection-toolbar__button.is-active {
  opacity: 1;
}

@media screen and (min-width: 700px) {
  .collection-toolbar {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .collection-toolbar__button {
    padding-block: 1.125rem;
  }

  .collection-toolbar__button-list {
    flex-direction: row-reverse;
    flex: none;
  }

  .collection-toolbar__button-container {
    border-inline-start-width: 1px;
  }

  .collection-toolbar__button-container > .collection-toolbar__button {
    width: max-content;
    padding-inline: 2.875rem;
  }

  .collection-toolbar__layout-switch-list {
    border-inline-width: 0 1px;
    padding-inline: 1.875rem;
  }

  .collection-toolbar__products-count {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
  }
}

.facets .accordion-list .accordion:first-child {
  border-block-start-width: 0;
}

.facets .accordion-list .accordion:first-child .accordion__toggle {
  padding-block-start: 0;
}

.facets .accordion-list .accordion:last-child {
  border-block-end-width: 0;
}

.facets .accordion-list .accordion:last-child .accordion__content {
  padding-block-end: 0;
}

.active-facets {
  flex-wrap: wrap;
  align-items: center;
  gap: .625rem;
  display: flex;
}

.removable-facet {
  font-size: var(--text-sm);
  border-width: 1px;
  align-items: center;
  column-gap: .375rem;
  padding: .375rem .625rem;
  display: flex;
}

.facets-clear-all {
  margin-inline-start: .625rem;
}

@media screen and (min-width: 700px) {
  .removable-facet {
    font-size: var(--text-base);
    column-gap: .625rem;
    padding: .625rem .875rem;
  }
}

@media screen and (min-width: 1000px) {
  .facets-sidebar {
    top: calc(var(--sticky-area-height)  + var(--collection-toolbar-height, 0px)  + 20px);
    width: var(--collection-sidebar-width);
    grid-column-end: -2;
    display: block;
    position: sticky;
  }
}

.collection-list {
  gap: var(--collection-list-gap, 0px);
  display: flex;
}

.collection-list .collection-card {
  flex-basis: var(--collection-list-item-size, calc(100% / var(--collection-list-items-per-row)  - var(--collection-list-gap, 0px) * (var(--collection-list-items-per-row)  - 1) / var(--collection-list-items-per-row)));
}

.collection-list--text-outside {
  row-gap: 3rem;
}

.collection-card {
  align-content: start;
  gap: 1.5rem;
  display: grid;
}

.countdown {
  gap: var(--section-stack-gap);
  display: grid;
}

.countdown__timer {
  text-transform: uppercase;
  text-align: center;
  grid: auto / auto-flow minmax(0, 1fr) auto;
  align-items: center;
  column-gap: .75rem;
  display: grid;
}

.countdown__timer-item {
  display: grid;
}

.countdown__timer-flip {
  --heading-letter-spacing: 0;
  grid: auto / auto-flow minmax(0, 1fr);
  column-gap: 2px;
  display: grid;
}

.countdown__timer-flip::part(digit) {
  color: rgb(var(--countdown-timer-flip-text-color));
}

.countdown__timer-flip--background::part(digit) {
  background: rgb(var(--countdown-timer-flip-background));
  border-radius: .25rem;
  padding: .375rem .5rem;
}

@media screen and (max-width: 699px) {
  .countdown__content {
    text-align: center;
  }

  .countdown__timer {
    justify-content: center;
  }
}

@media screen and (min-width: 700px) {
  .countdown {
    width: 100%;
    grid-template-columns: minmax(250px, 570px) max-content;
    justify-content: space-between;
    gap: 4rem;
  }

  .countdown__timer-item {
    row-gap: .5rem;
  }

  .countdown--reverse {
    grid-template-columns: max-content minmax(250px, 570px);
  }

  .countdown--reverse .countdown__timer {
    order: -1;
  }
}

@media screen and (min-width: 1000px) {
  .countdown__timer {
    column-gap: 1rem;
  }
}

.countdown-condensed {
  column-gap: 2.25rem;
  justify-content: var(--countdown-condensed-justify-content, space-between);
  padding: 1rem var(--container-gutter);
  display: flex;
}

.countdown-condensed__text {
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem 1.5rem;
  display: flex;
}

.countdown-condensed__timer {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-style: var(--heading-font-style);
  letter-spacing: .2em;
  text-transform: uppercase;
  text-align: center;
  flex-shrink: 0;
  align-items: center;
  column-gap: .25rem;
  display: flex;
}

.countdown-condensed__timer-item {
  display: grid;
}

.countdown-condensed__timer-flip {
  font-size: .625rem;
  font-weight: bolder;
}

.countdown-condensed__timer-unit {
  font-size: .5rem;
}

@media screen and (max-width: 699px) {
  .countdown-condensed .h6 {
    font-size: .625rem;
  }
}

@media screen and (min-width: 700px) {
  .countdown-condensed {
    column-gap: 5rem;
  }

  .countdown-condensed__timer {
    column-gap: .75rem;
  }

  .countdown-condensed__timer-flip {
    font-size: .75rem;
  }

  .countdown-condensed__timer-unit {
    font-size: .625rem;
  }
}

.footer {
  --background: var(--footer-background);
  --text-color: var(--footer-text);
  --border-color: var(--footer-border-color);
  background: rgb(var(--background));
  color: rgb(var(--text-color));
  padding-block-start: var(--section-vertical-spacing);
  padding-block-end: min(var(--section-vertical-spacing), 3rem);
}

.footer__inner {
  row-gap: min(var(--section-vertical-spacing), 3rem);
  display: grid;
}

.footer__block-list {
  justify-content: start;
  align-items: start;
  gap: min(var(--section-vertical-spacing), 2.5rem);
  display: grid;
}

.footer__aside {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  display: grid;
}

.footer__aside .payment-methods {
  max-width: 30rem;
  justify-content: center;
}

@media screen and (min-width: 700px) {
  .footer__block-list {
    justify-content: var(--footer-content-justify-items);
    flex-wrap: wrap;
    gap: 3rem;
    display: flex;
  }

  .footer__block {
    flex: auto;
  }

  .footer__block--links, .footer__block--image {
    max-width: 14rem;
    flex-grow: 0;
  }

  .footer__block--newsletter {
    max-width: 18rem;
    flex-basis: 14rem;
  }

  .footer__block--text {
    max-width: 24rem;
    flex-basis: 14rem;
  }
}

@media screen and (min-width: 1000px) {
  .footer__aside {
    grid-auto-flow: column;
    justify-content: space-between;
  }

  .footer__aside .payment-methods {
    justify-content: end;
  }
}

@media screen and (min-width: 1150px) {
  .footer__block-list {
    justify-content: var(--footer-content-justify-items);
    column-gap: 3.75rem;
  }
}

@media screen and (min-width: 1400px) {
  .footer__block-list {
    column-gap: 5rem;
  }
}

.gift-card__image {
  max-width: 16.875rem;
  margin-inline: auto;
}

.gift-card__redeem-info {
  padding: 1.5rem;
}

.gift-card__save {
  place-items: center;
  gap: 1.5rem;
  display: grid;
}

.gift-card__qr-code {
  width: 5rem;
  height: 5rem;
}

@media screen and (min-width: 700px) {
  .gift-card__redeem-info {
    padding: 2rem;
  }

  .gift-card__save {
    grid-auto-flow: column;
  }
}

.faq {
  justify-content: center;
  align-items: start;
  column-gap: 3.75rem;
  display: flex;
}

.faq__toc {
  max-width: 300px;
  min-width: 150px;
  align-content: start;
  justify-items: start;
  gap: .75rem;
  display: grid;
  position: sticky;
  inset-block-start: calc(var(--sticky-area-height)  + 1.25rem);
}

.faq__toc-item {
  text-underline: none;
  background: linear-gradient(to right, currentColor, currentColor) 0 min(100%, 1.35em) / 0 1px no-repeat;
  transition: background-size .3s ease-in-out;
}

.faq__toc-item.is-active {
  background-size: 100% 1px;
}

.faq__content {
  flex-basis: 650px;
}

.faq__category {
  flex-wrap: wrap;
  align-items: center;
  column-gap: 1rem;
  scroll-margin-block-start: 1.25rem;
  display: flex;
}

.faq__category + .accordion {
  margin-block-start: 1.25rem;
}

.accordion + .faq__category {
  margin-block-start: 3rem;
}

.featured-collections-navigation {
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: safe center;
  column-gap: 1.5rem;
  display: grid;
}

.featured-collections-navigation > button:not(:only-child) {
  padding-block-end: 2px;
  position: relative;
}

.featured-collections-navigation > button:not(:only-child):after {
  content: "";
  width: calc(100% - var(--heading-letter-spacing));
  height: 1px;
  transform-origin: var(--transform-origin-start);
  background: currentColor;
  transition: transform .3s;
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  transform: scaleX(0);
}

.featured-collections-navigation > button:not(:only-child)[aria-current="true"]:after {
  transform: scaleX(1);
}

@media screen and (min-width: 700px) {
  .featured-collections-navigation {
    column-gap: 2.5rem;
  }
}

.featured-collections-carousel {
  display: grid;
  position: relative;
}

.featured-collections-carousel > * {
  grid-area: 1 / -1;
}

.featured-collections-carousel__item {
  gap: var(--section-stack-gap);
  grid-template-columns: minmax(0, 1fr);
  display: grid;
}

.featured-collections-carousel__item:not(.is-selected) {
  display: none;
}

.header {
  --header-show-transparent-logo: 0;
  --background: var(--header-background);
  --text-color: var(--header-text);
  --border-color: var(--header-border-color);
  grid: var(--header-grid);
  justify-content: center;
  align-items: center;
  column-gap: 1.25rem;
  row-gap: var(--header-padding-block);
  background: rgb(var(--background));
  color: rgb(var(--text-color));
  box-shadow: 0 -1px rgb(var(--header-separation-border-color)) inset;
  padding-block-start: var(--header-padding-block);
  padding-block-end: var(--header-padding-block);
  padding-inline-start: var(--container-gutter);
  padding-inline-end: var(--container-gutter);
  transition: background .2s, color .2s, border .2s, box-shadow .2s;
  display: grid;
  position: relative;
}

:has(.shopify-section:first-child [allow-transparent-header]):not(:has(.shopify-section--header + .shopify-section-group-header-group)) .header {
  margin-block-end: calc(-1 * var(--header-height, 0px));
}

:has(.shopify-section:first-child [allow-transparent-header]):not(:has(.shopify-section--header + .shopify-section-group-header-group)) .header:not(:hover, .is-solid, :has([open])) {
  --header-show-transparent-logo: 1;
  --header-separation-border-color: 0 0 0 / 0;
  --background: 0 0 0 / 0;
  --border-color: 0 0 0 / 0;
  --text-color: var(--header-transparent-header-text-color);
}

:has(.shopify-section:first-child [allow-transparent-header]):not(:has(.shopify-section--header + .shopify-section-group-header-group)) .header:not(:hover, .is-solid, :has([open])) .link-faded {
  color: currentColor;
  transition: none;
}

.header__cart-dot {
  width: .5rem;
  height: .5rem;
  box-shadow: 0 0 0 2px rgb(var(--background));
  background-color: currentColor;
  border-radius: 100%;
  transition: transform .2s ease-in-out;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: -.125rem;
  transform: scale(0);
}

.header__cart-dot.is-visible {
  transform: scale(1);
}

@media screen and (min-width: 700px) {
  .header {
    column-gap: 2.5rem;
  }
}

.header__logo {
  grid-area: logo;
  justify-self: start;
  position: relative;
}

.header__logo-image {
  width: var(--header-logo-width);
  height: var(--header-logo-height);
  max-width: 100%;
  opacity: calc(1 - var(--header-show-transparent-logo));
  transition: opacity .2s;
}

.header__logo-image--transparent {
  opacity: var(--header-show-transparent-logo);
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.header__logo-text {
  display: block;
}

.header__primary-nav {
  flex-wrap: wrap;
  grid-area: primary-nav;
  align-items: center;
  gap: .625rem 1.25rem;
  display: flex;
}

.header__primary-nav-item:before {
  content: attr(data-title);
  height: 2px;
  width: max-content;
  transform-origin: var(--transform-origin-start);
  background: rgb(var(--text-color));
  color: #0000;
  font-size: var(--text-h6);
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-style: var(--heading-font-style);
  letter-spacing: var(--heading-letter-spacing);
  text-transform: var(--heading-text-transform);
  pointer-events: none;
  transition: transform .2s ease-in-out;
  position: absolute;
  inset-block-end: 0;
  transform: scaleX(0);
}

.header__primary-nav-item:hover:before {
  transform: scaleX(1);
}

.header__primary-nav-item:has(.header__dropdown-menu):before {
  display: none;
}

@media screen and (max-width: 699px) {
  .header__nav-icon {
    width: 1.375rem;
  }
}

@media screen and (min-width: 1000px) {
  .header__primary-nav--center {
    justify-content: safe center;
  }
}

@media screen and (min-width: 1150px) {
  .header__primary-nav {
    column-gap: 2.5rem;
  }
}

.header__secondary-nav {
  flex-wrap: wrap;
  grid-area: secondary-nav;
  justify-content: end;
  justify-self: end;
  align-items: center;
  gap: .625rem .9rem;
  display: flex;
}

.header__secondary-nav .localization-selectors:not(:only-child) {
  margin-inline-end: .625rem;
}

@media screen and (min-width: 700px) {
  .header__secondary-nav {
    column-gap: 1.25rem;
  }
}

.header__dropdown-menu {
  width: max-content;
  min-width: 12.5rem;
  max-width: 17.5rem;
  background: rgb(var(--header-background));
  color: rgb(var(--header-text));
  box-shadow: 2px 2px 6px rgb(var(--text-color) / .05);
  border-width: 0 1px 1px;
  padding-block: 1.125rem;
  position: absolute;
  inset-block-start: calc(100% - 2px);
}

.header__dropdown-menu:not(:has(.header__dropdown-menu)) {
  max-height: calc(100vh - var(--header-height, 0px)  - var(--announcement-bar-height, 0px)  - 20px);
  overflow: auto;
}

.header__dropdown-menu:before {
  content: "";
  height: 2px;
  width: 100%;
  transform-origin: var(--transform-origin-start);
  background: rgb(var(--text-color));
  transition: transform .25s ease-in-out 50ms;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  transform: scaleX(0);
}

[aria-expanded="true"] > .header__dropdown-menu:before {
  transform: scaleX(1);
}

.header__dropdown-menu :is(a, summary) {
  padding: .375rem 1.5625rem;
  display: block;
}

.header__dropdown-menu .header__menu-disclosure {
  position: relative;
}

.header__dropdown-menu--restrictable {
  max-height: calc(100vh - var(--header-height, 0px)  - var(--announcement-bar-height, 0px)  - 40px);
  overflow-y: auto;
}

.header__dropdown-menu .header__dropdown-menu {
  border-width: 1px;
  inset-block-start: calc(-1.125rem - 1px);
  inset-inline-start: 100%;
}

.header__dropdown-menu .header__dropdown-menu:before {
  display: none;
}

.header-search {
  width: 100%;
  z-index: -1;
  display: none;
  position: absolute;
  inset-block-start: 100%;
  inset-inline-start: 0;
}

.header-search::part(content) {
  max-height: calc(100vh - var(--header-height, 0px)  - var(--announcement-bar-height, 0px)  - 1.25rem);
  background: rgb(var(--background-primary));
  color: rgb(var(--text-primary));
  overflow: auto;
}

@supports (max-height: 100dvh) {
  .header-search::part(content) {
    max-height: min(var(--header-search-max-height, 100vh), 100dvh - var(--header-height, 0px)  - var(--announcement-bar-height, 0px)  - 1.25rem);
  }
}

.header-search__form-control {
  grid-template-columns: auto minmax(0, 1fr) auto;
  align-items: center;
  column-gap: .75rem;
  padding-block: .75rem;
  display: grid;
}

.header-search__input {
  -webkit-appearance: none;
  appearance: none;
  background: none;
}

.header-search__input:focus {
  outline: none;
}

.header-search__input::-webkit-search-decoration, .header-search__input::-webkit-search-cancel-button, .header-search__input::-webkit-search-results-button, .header-search__input::-webkit-search-results-decoration {
  display: none;
}

@media screen and (min-width: 700px) {
  .header-search__form-control {
    column-gap: 1.25rem;
    padding-block: 1.5rem;
  }
}

.predictive-search {
  display: block;
}

.predictive-search__no-results {
  text-align: center;
  padding-block: 2.5rem 4rem;
}

.predictive-search__results {
  grid-template-columns: minmax(0, 1fr);
  gap: 2.5rem;
  padding-block: 1.25rem 2rem;
  display: grid;
}

.predictive-search__suggestions {
  grid-template-columns: minmax(0, 1fr);
  justify-items: start;
  gap: .75rem 1.5rem;
  display: grid;
}

.predictive-search__suggestions mark {
  background: none;
  font-weight: bolder;
}

.predictive-search__tabs::part(tab-list-scrollable) {
  scrollbar-width: none;
  margin-inline-start: calc(-1 * var(--distance-to-bleed));
  margin-inline-end: calc(-1 * var(--distance-to-bleed));
  padding-inline-start: var(--distance-to-bleed);
  padding-inline-end: var(--distance-to-bleed);
  display: flex;
  overflow: auto;
}

.predictive-search__tabs::part(tab-list) {
  min-width: 100%;
  width: min-content;
}

.predictive-search__category {
  border-block-end-width: 1px;
  padding-block-end: .625rem;
}

.predictive-search__products {
  grid-template-columns: minmax(0, 1fr);
  align-items: end;
  gap: 1.25rem;
  display: grid;
}

.predictive-search__blog-posts {
  grid-template-columns: minmax(0, 1fr);
  gap: 1.5rem 2.5rem;
  display: grid;
}

.predictive-search__blog-posts .blog-post-card__info {
  row-gap: .75rem;
}

.predictive-search__collections {
  align-items: start;
  gap: var(--container-gutter);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

@media screen and (max-width: 699px) {
  .predictive-search__suggestions {
    grid: auto / auto-flow max-content;
    row-gap: 1.25rem;
  }

  .predictive-search__products .horizontal-product-card__figure {
    max-width: 4.375rem;
  }

  .predictive-search__blog-posts .blog-post-card {
    grid-template-columns: minmax(0, 160px) minmax(0, 1fr);
    align-items: center;
  }
}

@media screen and (min-width: 700px) {
  .predictive-search__no-results {
    padding-block: 4.75rem 6.25rem;
  }

  .predictive-search__results {
    padding-block: 1.5rem 3rem;
  }

  .predictive-search__blog-posts {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .predictive-search__blog-posts .blog-post-card__category {
    font-size: .6875rem;
  }
}

@media screen and (min-width: 1000px) {
  .predictive-search__results--with-suggestions {
    grid-template-columns: 250px minmax(0, 1fr);
  }

  .predictive-search__products {
    grid-template-columns: repeat(var(--product-list-items-per-row, 4), minmax(0, 1fr));
  }

  .predictive-search__blog-posts {
    grid-template-columns: repeat(var(--blog-post-list-items-per-row, 4), minmax(0, 1fr));
  }

  .predictive-search__collections {
    grid-template-columns: repeat(var(--collection-list-items-per-row), minmax(0, 1fr));
  }
}

.mega-menu {
  --mega-menu-gap: 2.5rem;
  --mega-menu-linklist-gap: 2.5rem;
  --mega-menu-promo-gap: 2rem;
  width: 100%;
  max-height: calc(100vh - var(--header-height, 0px)  - var(--announcement-bar-height, 0px)  - 20px);
  justify-content: safe center;
  align-items: start;
  column-gap: var(--mega-menu-gap);
  background: rgb(var(--header-background));
  color: rgb(var(--header-text));
  border-block-end-width: 1px;
  padding-block: 2.5rem;
  padding-inline-start: var(--container-gutter);
  padding-inline-end: var(--container-gutter);
  display: flex;
  position: absolute;
  inset-block-start: 100%;
  inset-inline-start: 0;
  overflow-y: auto;
}

.mega-menu--reverse {
  flex-direction: row-reverse;
}

.mega-menu--reverse .mega-menu__promo {
  justify-content: flex-start;
}

.mega-menu__linklist {
  gap: var(--mega-menu-linklist-gap);
  flex-wrap: wrap;
  flex: 0 auto;
  display: flex;
}

.mega-menu__linklist > * {
  max-width: 280px;
}

.mega-menu__promo {
  justify-content: flex-end;
  column-gap: var(--mega-menu-promo-gap);
  flex: 0 auto;
  display: flex;
}

.mega-menu__promo > * {
  min-width: 240px;
  max-width: 315px;
  flex: 315px;
}

@media screen and (min-width: 1400px) {
  .mega-menu {
    --mega-menu-gap: 6.25rem;
    --mega-menu-linklist-gap: 2.5rem 6.25rem;
  }

  .mega-menu:has(.mega-menu__linklist > :nth-child(5)) {
    --mega-menu-linklist-gap: 2.5rem 4rem;
  }
}

.header-sidebar {
  --header-linklist-padding-block: 1rem;
}

.header-sidebar::part(close-button) {
  opacity: 1;
}

.header-sidebar::part(header) {
  border-block-end: none;
}

.header-sidebar::part(content) {
  box-sizing: border-box;
  overflow-x: hidden;
}

.header-sidebar::part(panel-list) {
  position: relative;
}

.header-sidebar__scroller {
  padding-block-end: var(--drawer-body-padding-block);
  padding-inline-start: var(--drawer-body-padding-inline);
  padding-inline-end: var(--drawer-body-padding-inline);
  overflow-y: auto;
}

.header-sidebar__linklist-button {
  width: 100%;
  text-align: start;
  justify-content: space-between;
  align-items: center;
  padding-block-start: calc(var(--header-linklist-padding-block) / 2);
  padding-block-end: calc(var(--header-linklist-padding-block) / 2);
  transition: color .2s;
  display: flex;
}

@media screen and (pointer: fine) {
  .header-sidebar__main-panel li:has([aria-expanded="true"]) ~ li .header-sidebar__linklist-button, .header-sidebar__main-panel li:has( ~ li [aria-expanded="true"]):not(:has([aria-expanded="true"])) .header-sidebar__linklist-button {
    color: rgb(var(--text-color) / .65);
  }
}

.header-sidebar__sub-panel li:has([aria-expanded="true"]) ~ li .header-sidebar__linklist-button, .header-sidebar__sub-panel li:has( ~ li [aria-expanded="true"]):not(:has([aria-expanded="true"])) .header-sidebar__linklist-button {
  color: rgb(var(--text-color) / .65);
}

.header-sidebar__sub-panel li:has([aria-expanded="true"]) .header-sidebar__linklist-button {
  color: rgb(var(--text-color)) !important;
}

.header-sidebar__back-button {
  width: 100%;
  border-block-end-width: 1px;
  margin-block-end: 1rem;
  padding-block-start: var(--header-linklist-padding-block);
  padding-block-end: 1rem;
}

.header-sidebar__back-button.is-divided {
  margin-block-end: .5rem;
}

.header-sidebar__nested-linklist {
  border-inline-start-width: 1px;
  row-gap: .75rem;
  margin-block: 2px 1.125rem;
  margin-inline-start: .5rem;
  padding-inline-start: 1.5rem;
  display: grid;
}

.header-sidebar__main-panel {
  height: 100%;
  background: rgb(var(--drawer-background));
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  grid-auto-columns: max-content;
  display: grid;
}

.header-sidebar__footer {
  position: relative;
}

.header-sidebar__footer:before {
  content: "";
  height: 2.5rem;
  width: 100%;
  background: linear-gradient(transparent, rgb(var(--drawer-background)));
  position: absolute;
  inset-block-end: 100%;
  inset-inline-start: 0;
}

.header-sidebar__footer > * {
  padding-block: 1.25rem;
  padding-inline-start: var(--drawer-footer-padding-inline);
  padding-inline-end: var(--drawer-footer-padding-inline);
}

.header-sidebar__footer .localization-selectors {
  border-block-start-width: 1px;
  justify-content: flex-start;
  column-gap: 1.25rem;
}

.header-sidebar__collapsible-panel {
  height: 100%;
  width: var(--drawer-max-width);
  background: rgb(var(--drawer-background));
  display: none;
  position: absolute;
  inset-block-start: 0;
}

.header-sidebar__promo {
  grid-auto-flow: column;
  gap: 2rem 1.25rem;
  margin-block-start: 1.25rem;
  display: flex;
}

.header-sidebar__promo > * {
  min-width: 12.5rem;
}

.header-sidebar__promo > *:only-child {
  min-width: 100%;
}

.header-sidebar__linklist.divide-y .header-sidebar__linklist-button {
  padding-block-start: var(--header-linklist-padding-block);
  padding-block-end: var(--header-linklist-padding-block);
}

.header-sidebar__linklist.divide-y .header-sidebar__back-button {
  padding-block-end: var(--header-linklist-padding-block);
}

.header-sidebar__linklist.divide-y .header-sidebar__nested-linklist {
  margin-block-end: calc(.5rem + var(--header-linklist-padding-block));
}

.header-sidebar__linklist.divide-y .header-sidebar__promo {
  margin-block-start: .75rem;
}

@media screen and (min-width: 700px) {
  .header-sidebar {
    --header-linklist-padding-block: 1.25rem;
  }
}

@media screen and (min-width: 1000px) {
  .header-sidebar::part(panel-list) {
    position: static;
  }

  .header-sidebar::part(content) {
    overflow-x: visible;
  }

  .header-sidebar:has(.header-sidebar__collapsible-panel[aria-activedescendant])::part(content) {
    border-inline-end: 1px solid rgb(var(--border-color));
  }

  .header-sidebar__main-panel {
    z-index: 1;
    position: relative;
  }

  .header-sidebar__collapsible-panel .header-sidebar__scroller {
    width: 100%;
    inset-block: min(var(--header-height), 5rem) 0;
    position: absolute;
  }

  .header-sidebar__promo {
    flex-direction: column;
  }

  .header-sidebar__promo > * {
    min-width: 100%;
  }
}

.image-with-text {
  display: grid;
  overflow: hidden;
}

.image-with-text > .prose {
  box-sizing: content-box;
  max-width: var(--image-with-text-content-max-width);
  margin-block: 1.5rem;
  padding-inline-start: var(--container-gutter);
  padding-inline-end: var(--container-gutter);
}

.image-with-text > :is(svg, img) {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 699px) {
  .image-with-text > .prose {
    margin-inline: auto;
  }
}

@media screen and (min-width: 700px) {
  .image-with-text {
    --image-with-text-gap: 2.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }

  .image-with-text > .prose {
    padding-inline-start: var(--image-with-text-gap);
  }

  .image-with-text--reverse > :is(picture, svg, img) {
    order: 9999;
  }

  .image-with-text--reverse > .prose {
    margin-inline-start: auto;
    padding-inline-end: var(--image-with-text-gap);
  }
}

@media screen and (min-width: 1000px) {
  .image-with-text {
    --image-with-text-gap: 6rem;
  }
}

.image-with-text-block > :not(img, svg, picture) {
  background: rgb(var(--background, var(--background-primary)));
  padding: 2.375rem 1.25rem;
  box-shadow: 0 1px 20px #3636364d;
}

@media screen and (min-width: 700px) {
  .image-with-text-block > :not(img, svg, picture) {
    padding: 3rem 1rem;
  }
}

.image-with-text-block--parallax {
  height: var(--content-over-media-height, auto);
  transform: unset;
  clip-path: inset(0);
}

@media (prefers-reduced-motion: no-preference) {
  .image-with-text-block--parallax > svg, .image-with-text-block--parallax > picture img {
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  @supports (min-height: 100lvh) {
    :is(.image-with-text-block--parallax > svg, .image-with-text-block--parallax > picture img) {
      min-height: 100lvh;
    }
  }
}

.images-with-text-scroll {
  align-items: start;
  display: grid;
  position: relative;
}

.images-with-text-scroll:before {
  content: "";
  background: rgb(var(--images-with-text-scroll-overlay));
  z-index: 1;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.images-with-text-scroll__image-background, .images-with-text-scroll__container {
  grid-area: 1 / -1;
}

.images-with-text-scroll__image-background {
  max-height: calc(100vh - var(--sticky-area-height));
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: sticky;
  inset-block-start: var(--sticky-area-height);
}

.images-with-text-scroll__container {
  z-index: 1;
  place-items: start center;
  row-gap: 2rem;
  padding-block-start: var(--section-vertical-spacing);
  padding-block-end: var(--section-vertical-spacing);
  display: grid;
}

.images-with-text-scroll__item {
  display: contents;
}

.images-with-text-scroll__item:not(:first-child) > .images-with-text-scroll__image {
  opacity: 0;
}

.images-with-text-scroll__image {
  grid-area: 1 / 1;
  align-self: center;
}

.images-with-text-scroll__text {
  max-width: 375px;
  text-align: center;
  grid-area: 2 / 1;
}

@media screen and (min-width: 700px) {
  .images-with-text-scroll__container {
    row-gap: 3rem;
  }
}

@media screen and (max-width: 999px) {
  .images-with-text-scroll__item:not(.is-selected) {
    visibility: hidden;
  }

  .images-with-text-scroll__image {
    width: min(56vw, 27.5rem);
  }
}

@media screen and (min-width: 1000px) {
  .images-with-text-scroll {
    min-height: calc(100vh - var(--sticky-area-height));
  }

  .images-with-text-scroll__container {
    --image-width: min(50vw - var(--container-gutter), 575px);
    --image-height: min(var(--image-width), var(--image-width) / var(--images-with-text-scroll-master-image-ratio));
    --content-padding: calc((100vh - var(--image-height)  - var(--sticky-area-height)) / 2);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: minmax(0, 1fr);
    align-items: center;
    padding-block-start: var(--content-padding);
    padding-block-end: var(--content-padding);
  }

  .images-with-text-scroll__image {
    height: var(--image-height);
    object-fit: contain;
    object-position: center;
    position: sticky;
    inset-block-start: calc(var(--content-padding)  + var(--sticky-area-height));
  }

  .images-with-text-scroll__text {
    text-align: var(--images-with-text-scroll-text-alignment);
    grid-area: auto / 2;
  }

  .images-with-text-scroll--reverse .images-with-text-scroll__image {
    grid-column: 2;
  }

  .images-with-text-scroll--reverse .images-with-text-scroll__text {
    grid-column: 1;
  }
}

.logo-list {
  grid: auto / repeat(var(--logo-list-items-per-row), minmax(0, 250px));
  justify-content: center;
  gap: var(--logo-list-gap);
  display: grid;
}

.logo-list__item {
  outline: 1px solid rgb(var(--logo-list-item-border-color));
  place-items: center;
  padding: 1.25rem;
  display: grid;
}

@media screen and (min-width: 700px) {
  .logo-list__item {
    padding: 2rem 1.5rem;
  }
}

.media-grid {
  --media-grid-column-count: 2;
  align-items: start;
  gap: var(--media-grid-gap);
  grid: auto-flow dense var(--media-grid-row-height) / repeat(var(--media-grid-column-count), minmax(0, 1fr));
  display: grid;
}

.media-grid__item {
  grid-area: span min(2, var(--media-grid-item-row-span)) / span 2;
  height: 100%;
  container-type: inline-size;
}

.media-grid__item > * {
  height: 100%;
}

@media screen and (min-width: 700px) {
  .media-grid {
    --media-grid-column-count: 12;
  }

  .media-grid__item {
    grid-area: span var(--media-grid-item-row-span) / span var(--media-grid-item-column-span);
  }
}

.multi-column {
  grid: var(--multi-column-grid);
  column-gap: var(--multi-column-gap);
  row-gap: var(--section-stack-gap);
  justify-content: var(--multi-column-content-alignment);
  display: grid;
}

.multi-column__item {
  align-content: start;
  gap: 2rem;
  display: grid;
}

.multi-column__item--overlap {
  gap: 0;
}

.multi-column__item--overlap > .prose {
  isolation: isolate;
  margin-block-start: -.875rem;
}

.multiple-media-with-text {
  --multiple-media-with-text-gap: 2rem;
  grid: var(--multiple-media-with-text-grid);
  gap: var(--multiple-media-with-text-gap);
  display: grid;
}

.multiple-media-with-text__media-wrapper {
  grid-area: media-wrapper;
  grid: var(--multiple-media-with-text-media-grid);
  column-gap: var(--multiple-media-with-text-media-gap);
  display: grid;
}

.multiple-media-with-text__media-wrapper > * {
  grid-row: 1 / -1;
}

.multiple-media-with-text__content-wrapper {
  grid-area: content-wrapper;
}

@media screen and (min-width: 1000px) {
  .multiple-media-with-text {
    --multiple-media-with-text-gap: 2rem 4rem;
  }
}

@media screen and (min-width: 1150px) {
  .multiple-media-with-text {
    --multiple-media-with-text-gap: 2rem 7.5rem;
  }
}

.multiple-media-with-text--overlap .multiple-media-with-text__media-wrapper > :first-child {
  grid-column: 1 / 3;
}

.multiple-media-with-text--overlap .multiple-media-with-text__media-wrapper > :nth-child(2) {
  grid-column: 2 / 4;
}

@media screen and (min-width: 700px) {
  .multiple-media-with-text--overlap.multiple-media-with-text--reverse .multiple-media-with-text__media-wrapper > :first-child {
    grid-column: 2 / 4;
  }

  .multiple-media-with-text--overlap.multiple-media-with-text--reverse .multiple-media-with-text__media-wrapper > :nth-child(2) {
    grid-column: 1 / 3;
  }
}

@media screen and (min-width: 700px) {
  .newsletter .form {
    width: 35rem;
  }
}

@media screen and (min-width: 700px) {
  .newsletter-popup {
    width: 23.75rem;
    inset-inline-start: auto;
  }
}

.password__inner {
  isolation: isolate;
  min-height: 100vh;
  align-content: space-between;
  row-gap: 3rem;
  padding-block: 1.6rem;
  display: grid;
}

@supports (height: 100dvh) {
  .password__inner {
    min-height: 100dvh;
  }
}

.password__header {
  justify-items: center;
  display: grid;
}

.password__header > * {
  grid-area: 1 / -1;
}

.password__password-button {
  margin-inline-start: auto;
}

.password__block-list {
  width: min(100%, 30rem);
  justify-self: center;
  row-gap: .625rem;
  display: grid;
}

.password__content, .password__social {
  padding: 1.25rem;
}

.password__social {
  display: grid;
}

.password__social .social-media {
  justify-content: center;
}

.password__footer {
  flex-wrap: wrap;
  justify-content: center;
  gap: .75rem;
  display: flex;
}

@media screen and (min-width: 700px) {
  .password__content {
    padding: 2.5rem;
  }

  .password__footer {
    justify-content: space-between;
  }
}

.prev-next-blog-posts {
  gap: 3.125rem;
  display: grid;
}

@media screen and (min-width: 700px) {
  .prev-next-blog-posts {
    grid: auto / auto-flow minmax(min-content, 31.25rem);
    justify-content: center;
    gap: 1.875rem;
  }
}

@media screen and (min-width: 1150px) {
  .prev-next-blog-posts {
    gap: 3.75rem;
  }
}

@media screen and (min-width: 700px) {
  .privacy-banner {
    width: 31.25rem;
  }
}

.shopify-section--main-product .section-spacing {
  margin-block-start: 0;
}

.product {
  grid: var(--product-grid, none);
  align-items: start;
  gap: var(--product-gap, 1.875rem);
  display: grid;
}

@media screen and (min-width: 1000px) {
  .shopify-section--main-product .section-spacing {
    margin-block-start: var(--container-gutter);
  }

  .product {
    --product-gap: 3.5rem;
  }
}

@media screen and (min-width: 1150px) {
  .product {
    --product-gap: 5rem;
  }
}

.product-gallery {
  --product-gallery-gap: 1.5rem;
  --product-gallery-thumbnail-list-gap: 1rem .5rem;
  --product-gallery-thumbnail-size: 3.5rem;
  flex-direction: var(--product-gallery-flex-direction, column);
  align-items: start;
  gap: var(--product-gallery-gap) var(--product-gap, 0px);
  grid-area: product-gallery;
  display: flex;
  position: relative;
}

.product-gallery__zoom-button {
  z-index: 1;
  transition: opacity .2s, visibility .2s;
  position: absolute;
  inset-block-start: 1.25rem;
  inset-inline-end: 0;
}

.product-gallery__zoom-button--hidden {
  opacity: 0;
  visibility: hidden;
}

.product-gallery__image-list {
  width: 100%;
  row-gap: var(--product-gallery-gap);
  grid-auto-rows: max-content;
  grid-auto-columns: minmax(0, 1fr);
  display: grid;
}

.product-gallery__carousel {
  grid: var(--product-gallery-carousel-grid, auto / auto-flow 100%);
  align-items: start;
  gap: var(--product-gallery-carousel-gap, 1.5rem min(1.5rem, var(--container-gutter)));
  scroll-snap-type: var(--product-gallery-carousel-scroll-snap-type, x mandatory);
  display: grid;
  position: relative;
}

.product-gallery[allow-zoom] .product-gallery__media {
  cursor: var(--cursor-zoom-in-svg-url) 18 18, zoom-in;
}

.product-gallery__media > * {
  width: 100%;
}

.product-gallery__thumbnail-list {
  max-width: 100%;
  flex-shrink: 0;
  position: relative;
}

.product-gallery__thumbnail-scroller {
  grid-auto-columns: var(--product-gallery-thumbnail-size);
  grid-auto-flow: var(--product-gallery-thumbnail-list-grid-auto-flow, column);
  align-content: start;
  align-items: start;
  gap: var(--product-gallery-thumbnail-list-gap);
  max-height: var(--product-gallery-thumbnail-list-max-height, none);
  scroll-snap-type: both mandatory;
  overscroll-behavior: contain;
  scrollbar-width: none;
  grid-template-columns: none;
  display: grid;
  position: relative;
  overflow: auto;
}

.product-gallery__thumbnail-scroller::-webkit-scrollbar {
  display: none;
}

.product-gallery__thumbnail {
  border: 1px solid #0000;
  padding: 1px;
  transition: border-color .1s;
  position: relative;
}

.product-gallery__thumbnail[aria-current="true"] {
  border-color: currentColor;
}

.product-gallery__media-badge {
  position: absolute;
  inset-block-start: 3px;
  inset-inline-end: 3px;
}

@media screen and (max-width: 999px) {
  .product-gallery__carousel-with-arrows {
    align-items: center;
    display: flex;
  }

  .product-gallery__carousel-with-arrows .product-gallery__media > img {
    width: 45vw;
    margin-inline: auto;
  }
}

@media screen and (min-width: 1000px) {
  .product-gallery__thumbnail-list {
    position: sticky;
    inset-block-start: calc(var(--sticky-area-height)  + 20px);
  }

  .product-gallery__media {
    scroll-margin-block-start: 1.25rem;
  }
}

.product-info {
  --product-info-gap: 1rem;
  grid-area: product-info;
}

.product-info__block-list > :not(:first-child) {
  margin-block-start: var(--product-info-gap);
}

.product-info__block-list > :not(:last-child) {
  margin-block-end: var(--product-info-gap);
}

.product-info__block-item:empty, .product-info__block-item:not(:has( > :not([hidden]))) {
  display: none;
}

.product-info__block-item:is([data-block-type="vendor"], [data-block-type="title"], [data-block-type="sku"], [data-block-type="price"]) {
  --product-info-gap: .5rem;
}

.product-info__block-group:has(.product-info__block-item:is([data-block-type="vendor"], [data-block-type="title"], [data-block-type="sku"], [data-block-type="price"])) {
  --product-info-gap: .5rem;
}

.product-info__block-item:is([data-block-type="variant-picker"], [data-block-type="product-variations"], [data-block-type="separator"], [data-block-type="description"], [data-block-type="text"], [data-block-type="liquid"], [data-block-type="share-buttons"]) {
  --product-info-gap: 1.5rem;
}

.product-info__block-group:has(.product-info__block-item:is([data-block-type="variant-picker"], [data-block-type="product-variations"], [data-block-type="separator"], [data-block-type="description"], [data-block-type="text"], [data-block-type="liquid"], [data-block-type="share-buttons"])) {
  --product-info-gap: 1.5rem;
}

.product-info__block-item:is([data-block-type="inventory"], [data-block-type="buy-buttons"], [data-block-type="pickup-availability"], [data-block-type="more-information"], [data-block-type="offers"]) {
  --product-info-gap: 2rem;
}

.product-info__block-group:has(.product-info__block-item:is([data-block-type="inventory"], [data-block-type="buy-buttons"], [data-block-type="pickup-availability"], [data-block-type="more-information"], [data-block-type="offers"])) {
  --product-info-gap: 2rem;
}

.product-info__block-item[data-block-type="complementary-products"] {
  --product-info-gap: 3rem;
}

.product-info__block-group:has(.product-info__block-item[data-block-type="complementary-products"]) {
  --product-info-gap: 3rem;
}

@media screen and (max-width: 699px) {
  .product-info--center :is([data-block-type="vendor"], [data-block-type="title"], [data-block-type="sku"], [data-block-type="price"]) {
    text-align: center;
  }

  .product-info--center :is(.badge-list, .price-list, .rating-badge) {
    justify-content: center;
  }

  .product-info--center .text-with-rating {
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (min-width: 1000px) {
  .product-info {
    position: sticky;
    inset-block-start: calc(var(--sticky-area-height)  + 20px);
  }
}

.product-content-below-gallery {
  grid-area: product-content;
}

.product-content-below-gallery product-recommendations:not(:first-child) {
  margin-block-start: max(48px, var(--product-gap, 0px));
}

.product-content-below-gallery product-recommendations:not(:last-child) {
  margin-block-end: max(48px, var(--product-gap, 0px));
}

.product-content-below-gallery__description:not(:last-child) {
  margin-block-end: 1.25rem;
}

.product-info__more-info {
  border-block-width: 1px;
  justify-content: space-between;
  align-items: center;
  padding: .9375rem;
  display: flex;
}

.product-info__more-info > svg {
  transition: transform .2s ease-in-out;
}

@media screen and (pointer: fine) {
  .product-info__more-info:hover > svg {
    transform: translateX(5px);
  }
}

.variant-picker .popover {
  --popover-block-offset: .5rem;
  --popover-body-padding-inline: 1.25rem;
  width: 100%;
}

.variant-picker .popover::part(content) {
  max-width: none;
  width: 100%;
}

:not(.is-disabled) > .variant-picker__dropdown-sold-out-label {
  display: none;
}

.buy-buttons {
  row-gap: 1rem;
  display: grid;
}

.pickup-location {
  align-items: start;
  gap: .25rem;
  display: grid;
}

.pickup-location + .pickup-location {
  padding-block-start: 1rem;
}

.product-offers .page-dots {
  padding-block-end: 1.5rem;
}

.product-offers__item {
  padding: 1.5rem;
}

.product-offers__list--carousel {
  grid: auto / auto-flow 100%;
  display: grid;
}

.complementary-products {
  max-width: 40rem;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 1.875rem;
  margin-inline: auto;
  display: grid;
}

.complementary-products__header {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.complementary-products__product-list {
  gap: 1.25rem;
  display: grid;
  position: relative;
}

.complementary-products__product-list--carousel {
  grid: auto / auto-flow 100%;
}

@media screen and (max-width: 699px) {
  .complementary-products__product-list--carousel .product-card__figure {
    max-width: 11.25rem;
    margin-inline: auto;
  }
}

@media screen and (min-width: 700px) {
  .complementary-products {
    row-gap: 1rem;
  }

  .complementary-products__header {
    text-align: start;
    justify-content: space-between;
  }
}

.product-sticky-bar {
  width: 100%;
  padding: var(--container-gutter);
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  transition: opacity .2s, visibility .2s;
  display: block;
  position: fixed;
  inset-block-end: 0;
  inset-inline-start: 0;
}

.product-sticky-bar.is-visible {
  opacity: 1;
  visibility: visible;
}

.product-sticky-bar__info img {
  width: 3.75rem;
}

@media screen and (min-width: 700px) {
  .product-sticky-bar {
    inset-block: var(--sticky-area-height) auto;
    background: rgb(var(--background-primary));
    border-block-end-width: 1px;
    grid-template-columns: minmax(0, 1fr) max-content;
    align-items: center;
    padding-block: 1.125rem;
    display: grid;
  }
}

.scrolling-content {
  text-align: start;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.scrolling-content[direction="right"] {
  text-align: end;
}

.scrolling-content::part(scroller) {
  min-width: max-content;
  display: inline-flex;
  position: relative;
}

.scrolling-content__item {
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.scrolling-content__item > * {
  margin-inline-start: calc(var(--scrolling-content-content-gap) / 2);
  margin-inline-end: calc(var(--scrolling-content-content-gap) / 2);
}

.scrolling-content__image > img {
  max-width: 1.5em;
}

.main-search-form {
  min-width: 18.75rem;
}

.main-search__linklist {
  max-width: 25rem;
  text-align: center;
  margin-inline: auto;
}

.shop-the-look__carousel {
  z-index: 2;
  grid: auto / auto-flow 100%;
  align-items: start;
  gap: .625rem;
  display: grid;
  position: relative;
  overflow: visible;
}

.shop-the-look__carousel.is-expanded {
  overflow: hidden;
}

.shop-the-look__image-wrapper {
  position: relative;
  overflow: hidden;
}

.shop-the-look__hot-spot-list {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.shop-the-look__hot-spot {
  --hot-spot-size: 1rem;
  top: calc(var(--shop-the-look-hot-spot-top)  - (var(--hot-spot-size) / 2));
  left: calc(var(--shop-the-look-hot-spot-left)  - (var(--hot-spot-size) / 2));
  width: var(--hot-spot-size);
  height: var(--hot-spot-size);
  border-radius: var(--rounded-full);
  transition: transform .2s ease-in-out;
  position: absolute;
}

.shop-the-look__hot-spot:after {
  --hot-spot-after-size: calc(var(--hot-spot-size) * 2.5);
  content: "";
  top: calc(50% - var(--hot-spot-after-size) / 2);
  left: calc(50% - var(--hot-spot-after-size) / 2);
  width: var(--hot-spot-after-size);
  height: var(--hot-spot-after-size);
  background: radial-gradient(50% 50% at 50% 50%, rgb(var(--background) / 0), rgb(var(--background) / .3));
  border-radius: var(--rounded-full);
  animation: 2s ease-in-out infinite alternate ping;
  position: absolute;
}

.shop-the-look__hot-spot[aria-current="true"] {
  transform: scale(1.3);
}

.shop-the-look__item-carousel {
  grid: auto / auto-flow 100%;
  justify-items: center;
  display: grid;
  position: relative;
}

.shop-the-look__item {
  position: relative;
}

.shop-the-look__popover .product-card__figure {
  width: 100%;
  max-width: 10rem;
  margin-inline: auto;
}

.shop-the-look__popover::part(base) {
  height: auto;
  inset: auto auto 0 0;
}

.shop-the-look__popover::part(overlay) {
  display: none;
}

@media screen and (max-width: 699px) {
  .shopify-section--shop-the-look:before {
    content: "";
    height: 100%;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    background: rgb(var(--background-primary));
    z-index: 2;
    transition: opacity .4s;
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
  }

  .shopify-section--shop-the-look:has(.shop-the-look__carousel.is-expanded):before {
    opacity: 1;
  }
}

@media screen and (min-width: 1000px) {
  .shop-the-look__carousel {
    grid: none;
    align-items: center;
  }

  .shop-the-look__item {
    max-width: var(--container-sm-max-width);
    grid: auto / min(550px, 60%) minmax(0, 1fr);
    grid-area: 1 / -1;
    align-items: center;
    gap: .3125rem;
    margin-inline: auto;
    display: grid;
  }

  .shop-the-look__item:not(.is-selected) {
    visibility: hidden;
  }

  .shop-the-look__item-product {
    max-width: 16.875rem;
    grid-area: 1 / -1;
  }

  .shop-the-look__item-product:not(.is-selected) {
    visibility: hidden;
  }

  .shop-the-look__item-content {
    opacity: 0;
    justify-items: center;
    gap: 1.5rem;
    display: grid;
  }
}

@media screen and (pointer: fine) {
  .shop-the-look__hot-spot:hover {
    transform: scale(1.3);
  }
}

.slideshow {
  --slideshow-controls-spacing: var(--container-gutter);
  background: rgb(var(--slideshow-background));
  display: block;
  position: relative;
}

.slideshow .page-dots {
  z-index: 1;
  position: absolute;
  inset-block-end: var(--slideshow-controls-spacing);
  inset-inline-end: var(--slideshow-controls-spacing);
}

.slideshow:has(.page-dots, .slideshow__volume-control) {
  --content-over-media-row-gap: 4rem;
}

.slideshow__slide:not(.is-selected) {
  visibility: hidden;
  position: absolute;
  inset: 0;
}

.slideshow__slide-content--boxed {
  padding: 1.5rem;
}

.slideshow__slide-content--with-border {
  outline: 1px solid rgb(var(--border-color));
}

.slideshow__slide-content--with-border-offset {
  outline-offset: -.75rem;
  padding: 2.25rem;
}

.slideshow__volume-control {
  z-index: 1;
  display: grid;
  position: absolute;
  inset-block-end: var(--slideshow-controls-spacing);
  inset-inline-start: var(--slideshow-controls-spacing);
}

.slideshow__next-section-button {
  z-index: 2;
  position: absolute;
  inset-block-end: calc(-1 * var(--circle-button-size) / 2);
  inset-inline-start: calc(50% - var(--circle-button-size) / 2);
}

.shopify-section:has(.slideshow__next-section-button) + .shopify-section {
  --section-vertical-spacing-block-start-compensation: 1.5625rem;
}

@media screen and (min-width: 700px) {
  .slideshow:has(.page-dots, .slideshow__volume-control) {
    --content-over-media-row-gap: 5rem;
  }

  .slideshow__slide-content--boxed {
    padding: 3.5rem;
  }

  .slideshow__slide-content--with-border-offset {
    outline-offset: -1rem;
  }
}

.shopify-section--testimonials {
  --section-vertical-spacing-override: calc(var(--section-vertical-spacing)  + 20px);
}

.testimonial-list {
  gap: var(--section-stack-gap);
  justify-items: center;
  display: grid;
}

.testimonial-item {
  max-width: 34rem;
  font-size: var(--testimonials-font-size);
  grid-area: 1 / -1;
  align-content: start;
  justify-items: center;
  gap: 2rem;
  display: grid;
}

.testimonial-item:not(.is-selected) {
  opacity: 0;
  visibility: hidden;
}

.testimonial-carousel {
  display: grid;
}

.testimonial-list__thumbnail-scroller {
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem 5.625rem;
}

.testimonial-list__thumbnail {
  transition: opacity .2s ease-in-out;
}

.testimonial-list__thumbnail[aria-current="false"] {
  opacity: .25;
}

.testimonial-list__thumbnail img {
  max-width: var(--image-max-width);
}

.testimonial-list__thumbnail .placeholder {
  width: var(--image-max-width);
}

.shopify-challenge__container, .shopify-email-marketing-confirmation__container {
  max-width: 56.25rem;
  margin-block-start: var(--section-with-border-vertical-spacing);
  margin-block-end: var(--section-with-border-vertical-spacing);
}

.shopify-policy__container {
  gap: var(--section-stack-gap);
  max-width: 56.25rem;
  grid-auto-columns: minmax(0, 1fr);
  margin-block-start: var(--section-with-border-vertical-spacing-tight);
  margin-block-end: var(--section-with-border-vertical-spacing-tight);
  display: grid;
}

.shopify-challenge__container {
  gap: 1rem;
  display: grid;
}

.shopify-challenge__container > form {
  display: contents;
}

.shopify-email-marketing-confirmation__container {
  text-align: center;
}

.shopify-policy__title h1 {
  font: inherit;
}

.text-with-icons {
  --text-with-icons-items-per-row: 2;
  --text-with-icons-column-gap: 1.5rem;
  --text-with-icons-row-gap: 2rem;
  justify-content: center;
  gap: var(--text-with-icons-row-gap) var(--text-with-icons-column-gap);
  flex-wrap: wrap;
  display: flex;
}

.text-with-icons > * {
  flex-basis: min(18.75rem, calc(100% / var(--text-with-icons-items-per-row)  - var(--text-with-icons-column-gap, 0px) * (var(--text-with-icons-items-per-row)  - 1) / var(--text-with-icons-items-per-row)));
}

.text-with-icons > :only-child {
  flex-basis: min(18.75rem, 100%);
}

@media screen and (max-width: 699px) {
  .text-with-icons:not(.text-with-icons--stacked) {
    grid: auto / auto-flow 100%;
    gap: 0;
    display: grid;
  }

  .text-with-icons:not(.text-with-icons--stacked) > * {
    grid-area: 1 / -1;
    padding-inline: 2.5rem;
  }

  .text-with-icons:not(.text-with-icons--stacked) > :not(.is-selected) {
    visibility: hidden;
  }
}

@media screen and (min-width: 700px) {
  .text-with-icons {
    --text-with-icons-items-per-row: 3;
    --text-with-icons-row-gap: 2.5rem;
  }
}

@media screen and (min-width: 1000px) {
  .text-with-icons {
    --text-with-icons-items-per-row: 5;
  }

  .text-with-icons:not(:has( > :nth-child(5))) {
    --text-with-icons-items-per-row: 4;
    --text-with-icons-column-gap: 3.75rem;
  }
}

.timeline__carousel, .timeline__item {
  display: grid;
}

:is(.timeline__carousel, .timeline__item) > * {
  grid-area: 1 / -1;
}

.timeline__item:not(.is-selected) {
  visibility: hidden;
}

.timeline__item-image-wrapper {
  overflow: hidden;
}

.timeline__item-image-wrapper :is(img, svg) {
  scale: 1.05;
}

.timeline__item-content {
  padding: var(--container-gutter);
}

@media screen and (max-width: 699px) {
  .shopify-section--timeline {
    --section-vertical-spacing: 0;
    border-block-start-width: 0;
  }

  .timeline__carousel {
    position: relative;
  }

  .timeline__carousel:after {
    content: "";
    background: #0006;
    position: absolute;
    inset: 0;
  }

  .timeline__item {
    --text-color: var(--timeline-item-mobile-text-color);
    color: rgb(var(--text-color));
  }

  .timeline__item-content {
    text-align: center;
    z-index: 1;
    align-self: center;
    padding-block: 3.75rem;
  }
}

@media screen and (min-width: 700px) {
  .timeline__item {
    background: rgb(var(--background-secondary));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .timeline__item > * {
    grid-area: unset;
  }

  .timeline__item-content {
    align-self: end;
  }
}

.timeline__nav {
  white-space: nowrap;
  grid-auto-flow: column;
  justify-content: safe center;
  align-items: center;
  column-gap: 2.5rem;
  display: grid;
}

.timeline__nav > button {
  height: var(--text-h4);
  line-height: 1;
  transition: opacity .25s ease-in-out, font-size .25s ease-in-out;
  position: relative;
}

.timeline__nav > button:after {
  content: "";
  width: calc(100% - var(--heading-letter-spacing));
  height: 3px;
  transform-origin: var(--transform-origin-start);
  background: currentColor;
  transition: transform .25s ease-in-out;
  position: absolute;
  inset-block-end: -1.25rem;
  inset-inline-start: 0;
  transform: scaleX(0);
}

.timeline__nav > [aria-current="false"] {
  opacity: .65;
}

.timeline__nav > [aria-current="true"] {
  font-size: var(--text-h4);
}

.timeline__nav > [aria-current="true"]:after {
  transform: scaleX(1);
}

@media screen and (max-width: 699px) {
  .timeline__nav {
    background: rgb(var(--background-secondary));
    padding-block: 1.25rem;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@media screen and (min-width: 700px) {
  .timeline__nav {
    margin-block-start: var(--section-stack-gap);
  }

  .timeline__nav > button:after {
    inset-block-end: -.5rem;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only[type="checkbox"]:focus-visible + label, .sr-only[type="radio"]:focus-visible + label {
  outline: 2px solid Highlight;
  outline: 2px solid -webkit-focus-ring-color;
}

.skip-to-content:focus-visible {
  z-index: 9999;
  width: auto;
  height: auto;
  clip: auto;
  color: #fff;
  background: #000;
  padding: 6px 12px;
  font-weight: bold;
  overflow: auto;
}

.tap-area {
  position: relative;
}

.tap-area:before {
  content: "";
  position: absolute;
  inset: -.5rem;
}

.js .js\:hidden, .no-js .no-js\:hidden {
  display: none;
}

@media print {
  .print\:hidden {
    display: none;
  }
}

@keyframes ping {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(.8);
  }
}

@media screen and (pointer: fine) and (prefers-reduced-motion: no-preference) {
  .features--zoom-image .zoom-image {
    transform-origin: center;
    transition: scale 8s cubic-bezier(.25, .46, .45, .94);
  }

  .features--zoom-image .group:hover .group-hover\:zoom {
    scale: 1.2;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .js [reveal-on-scroll="true"] {
    opacity: 0;
  }
}

.border {
  border-width: 1px;
}

.border-x {
  border-inline-width: 1px;
}

.border-y {
  border-block-width: 1px;
}

.border-t {
  border-block-start-width: 1px;
}

.border-b {
  border-block-end-width: 1px;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  border-inline-start-width: 1px;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  border-block-start-width: 1px;
}

.rounded-full {
  border-radius: var(--rounded-full);
}

.text-success {
  color: rgb(var(--success-text));
}

.text-warning {
  color: rgb(var(--warning-text));
}

.text-error {
  color: rgb(var(--error-text));
}

.text-subdued {
  color: rgb(var(--text-color) / .65);
}

.text-on-sale {
  color: rgb(var(--on-sale-text));
}

.text-stroke {
  -webkit-text-stroke: 1px currentColor;
  -webkit-text-fill-color: transparent;
}

.text-gradient {
  background-image: var(--gradient);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-stroke.text-gradient {
  color: rgb(var(--background));
  -webkit-text-stroke-color: transparent;
  -webkit-text-fill-color: unset;
}

.bg-error, .bg-success, .bg-warning {
  --border-color: var(--text-color) / .15;
}

.bg-error {
  background: rgb(var(--error-background));
}

.bg-success {
  background: rgb(var(--success-background));
}

.bg-warning {
  background: rgb(var(--warning-background));
}

.bg-secondary {
  background: rgb(var(--background-secondary));
}

.opacity-0 {
  opacity: 0;
}

.backdrop-blur {
  -webkit-backdrop-filter: blur(var(--backdrop-blur, 0px));
  backdrop-filter: blur(var(--backdrop-blur, 0px));
}

.filter-invert {
  filter: invert();
}

.lock {
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.scroll-area {
  scrollbar-width: none;
  overscroll-behavior-x: contain;
  overflow-x: auto;
  overflow-y: hidden;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.scroll-area::-webkit-scrollbar, .hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.snap-x {
  scroll-snap-type: x mandatory;
}

.snap-y {
  scroll-snap-type: y mandatory;
}

.snap-start {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.snap-center {
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.hidden, .empty\:hidden:empty {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.contents {
  display: contents;
}

@media screen and (min-width: 700px) {
  .sm\:hidden {
    display: none;
  }

  .sm\:block {
    display: block;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:table {
    display: table;
  }

  .sm\:table-cell {
    display: table-cell;
  }
}

@media screen and (min-width: 1000px) {
  .md\:hidden {
    display: none;
  }

  .md\:block {
    display: block;
  }

  .md\:grid {
    display: grid;
  }

  .md\:flex {
    display: flex;
  }

  .md\:table {
    display: table;
  }

  .md\:table-cell {
    display: table-cell;
  }
}

@media screen and (min-width: 1150px) {
  .lg\:hidden {
    display: none;
  }

  .lg\:block {
    display: block;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:table {
    display: table;
  }

  .lg\:table-cell {
    display: table-cell;
  }
}

@media screen and (max-width: 699px) {
  .sm-max\:hidden {
    display: none;
  }
}

@media screen and (max-width: 999px) {
  .md-max\:hidden {
    display: none;
  }
}

@media screen and (max-width: 1149px) {
  .lg-max\:hidden {
    display: none;
  }
}

@media screen and (max-width: 1399px) {
  .xl-max\:hidden {
    display: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .motion-reduce\:hidden {
    display: none;
  }

  .motion-reduce\:block {
    display: block;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.relative {
  position: relative;
}

.wrap {
  flex-wrap: wrap;
}

.grow {
  flex-grow: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: safe center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-center {
  justify-items: safe center;
}

.justify-items-end {
  justify-items: end;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-end {
  justify-self: end;
}

.align-start {
  align-items: start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.align-baseline {
  align-items: baseline;
}

.align-self-start {
  align-self: start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.place-self-start {
  place-self: start;
}

.place-self-start-center {
  place-self: start center;
}

.place-self-start-end {
  place-self: start end;
}

.place-self-center {
  place-self: center;
}

.place-self-center-start {
  place-self: center start;
}

.place-self-center-end {
  place-self: center end;
}

.place-self-end {
  place-self: end;
}

.place-self-end-start {
  place-self: end start;
}

.place-self-end-center {
  place-self: end center;
}

@media screen and (min-width: 700px) {
  .sm\:text-start {
    text-align: start;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-end {
    text-align: end;
  }

  .sm\:justify-start {
    justify-content: start;
  }

  .sm\:justify-center {
    justify-content: safe center;
  }

  .sm\:justify-end {
    justify-content: end;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:justify-evenly {
    justify-content: space-evenly;
  }

  .sm\:justify-items-start {
    justify-items: start;
  }

  .sm\:justify-items-center {
    justify-items: safe center;
  }

  .sm\:justify-items-end {
    justify-items: end;
  }

  .sm\:justify-self-start {
    justify-self: start;
  }

  .sm\:justify-self-center {
    justify-self: center;
  }

  .sm\:justify-self-end {
    justify-self: end;
  }

  .sm\:align-start {
    align-items: start;
  }

  .sm\:align-center {
    align-items: center;
  }

  .sm\:align-end {
    align-items: end;
  }

  .sm\:align-baseline {
    align-items: baseline;
  }

  .sm\:align-self-start {
    align-self: start;
  }

  .sm\:align-self-center {
    align-self: center;
  }

  .sm\:align-self-end {
    align-self: end;
  }

  .sm\:place-self-start {
    place-self: start;
  }

  .sm\:place-self-start-center {
    place-self: start center;
  }

  .sm\:place-self-start-end {
    place-self: start end;
  }

  .sm\:place-self-center {
    place-self: center;
  }

  .sm\:place-self-center-start {
    place-self: center start;
  }

  .sm\:place-self-center-end {
    place-self: center end;
  }

  .sm\:place-self-end {
    place-self: end;
  }

  .sm\:place-self-end-start {
    place-self: end start;
  }

  .sm\:place-self-end-center {
    place-self: end center;
  }
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.w-0 {
  width: 0;
}

.min-w-full {
  min-width: 100%;
}

.min-h-full {
  min-height: 100%;
}

.mx-auto {
  margin-inline: auto;
}

.my-auto {
  margin-block: auto;
}

.aspect-short {
  aspect-ratio: 4 / 3;
}

.aspect-tall {
  aspect-ratio: 2 / 3;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-4\.5 {
  gap: 1.125rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-5\.5 {
  gap: 1.375rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

@media screen and (min-width: 700px) {
  .sm\:gap-0\.5 {
    gap: .125rem;
  }

  .sm\:gap-1 {
    gap: .25rem;
  }

  .sm\:gap-1\.5 {
    gap: .375rem;
  }

  .sm\:gap-2 {
    gap: .5rem;
  }

  .sm\:gap-2\.5 {
    gap: .625rem;
  }

  .sm\:gap-3 {
    gap: .75rem;
  }

  .sm\:gap-3\.5 {
    gap: .875rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-4\.5 {
    gap: 1.125rem;
  }

  .sm\:gap-5 {
    gap: 1.25rem;
  }

  .sm\:gap-5\.5 {
    gap: 1.375rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-7 {
    gap: 1.75rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-10 {
    gap: 2.5rem;
  }

  .sm\:gap-12 {
    gap: 3rem;
  }

  .sm\:gap-14 {
    gap: 3.5rem;
  }

  .sm\:gap-16 {
    gap: 4rem;
  }
}

.object-cover {
  object-fit: cover;
  object-position: center;
}

.object-contain {
  object-fit: contain;
  object-position: center;
}

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.image-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.placeholder {
  background: rgb(var(--background-secondary));
  fill: rgb(var(--text-primary) / .5);
  color: rgb(var(--text-primary) / .5);
  display: block;
}

.placeholder--invert {
  background: rgb(var(--text-primary));
  fill: rgb(var(--background-primary));
  color: rgb(var(--background-primary));
}

.bold {
  font-weight: bold;
}

.smallcaps {
  text-transform: uppercase;
  letter-spacing: var(--heading-letter-spacing);
  font-size: .625rem;
}

.text-xxs {
  font-size: .6875rem;
}

.text-xs {
  font-size: var(--text-xs);
}

.text-sm {
  font-size: var(--text-sm);
}

.text-base {
  font-size: var(--text-base);
}

.text-lg {
  font-size: var(--text-lg);
}

.text-xl {
  font-size: var(--text-xl);
}

.heading, .h1, .h2, .h3, .h4, .h5, .h6, .prose :where(h1, h2, h3, h4, h5, h6) {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-style: var(--heading-font-style);
  letter-spacing: var(--heading-letter-spacing);
  text-transform: var(--heading-text-transform);
  overflow-wrap: anywhere;
}

.h1, :where(.prose h1) {
  font-size: var(--text-h1);
  line-height: 1.5;
}

.h2, :where(.prose h2) {
  font-size: var(--text-h2);
  line-height: 1.5;
}

.h3, :where(.prose h3) {
  font-size: var(--text-h3);
  line-height: 1.6;
}

.h4, :where(.prose h4) {
  font-size: var(--text-h4);
  line-height: 1.6;
}

.h5, :where(.prose h5) {
  font-size: var(--text-h5);
  line-height: 1.7;
}

.h6, :where(.prose h6) {
  font-size: var(--text-h6);
  line-height: 1.7;
}

.prose blockquote {
  line-height: 1.75;
}

@media screen and (min-width: 700px) {
  .sm\:text-xs {
    font-size: var(--text-xs);
  }

  .sm\:text-sm {
    font-size: var(--text-sm);
  }

  .sm\:text-base {
    font-size: var(--text-base);
  }

  .sm\:text-lg {
    font-size: var(--text-lg);
  }

  .sm\:h1 {
    font-size: var(--text-h1);
    line-height: 1.5;
  }

  .sm\:h2 {
    font-size: var(--text-h2);
    line-height: 1.5;
  }

  .sm\:h3 {
    font-size: var(--text-h3);
    line-height: 1.6;
  }

  .sm\:h4 {
    font-size: var(--text-h4);
    line-height: 1.6;
  }

  .sm\:h5 {
    font-size: var(--text-h5);
    line-height: 1.7;
  }

  .sm\:h6 {
    font-size: var(--text-h6);
    line-height: 1.7;
  }
}

@media screen and (min-width: 1150px) {
  .h1, :where(.prose h1) {
    line-height: 1.3;
  }

  .h2, :where(.prose h2) {
    line-height: 1.4;
  }

  .h3, :where(.prose h3) {
    line-height: 1.5;
  }
}

ol {
  margin-inline-start: 2ch;
}

ul {
  margin-inline-start: 1em;
}

:where(ol, ul):not(.unstyled-list) li {
  padding-inline-start: .25rem;
  margin-block-start: 0 !important;
}

:where(.text-center, .text-end) :is(ol, ul) {
  margin-inline: 0;
  list-style-position: inside;
}

:where(.text-center, .text-end) :is(ol, ul) li {
  padding-inline-start: 0;
}

.line-through {
  text-decoration: line-through;
}

.break-all {
  word-break: break-all;
}

.hyphenate {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: var(--truncate-text-max-width, 100%);
  overflow: hidden;
}

.line-clamp {
  -webkit-line-clamp: var(--line-clamp-count, 2);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
